import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	PromotionalOfferResource,
	GetListDataPaginator,
	V1PromotionalOffersListRequestParams,
} from 'util/api/swaggerApi/data-contracts';

import { State as GlobalState } from './reducers';

/**
 * 取得優惠方案列表
 * @param tags 優惠方案標籤
 * @param isFeatured 是否取得精選優惠方案
 * @param page 頁碼
 */
export const getPromotionalOffers = createAction(
	'GET_PROMOTIONAL_OFFERS',
  	(params: V1PromotionalOffersListRequestParams) =>
  	async (dispatch: Dispatch) => {
   		const { tags, is_featured: isFeatured, page } = params;
   		const { v1PromotionalOffersList } = api;
    	const { data, message } = await v1PromotionalOffersList({
				tags,
				is_featured: isFeatured,
				page,
   	  });

    	if (message !== 'success') {
    	  return [];
    	}

    	if (isFeatured) {
    	  dispatch(setFeaturedPromotionalOffers(data?.data || [] as PromotionalOfferResource[]));
    	  return [] as PromotionalOfferResource[];
   	  }

    	dispatch(setPaginationMetaData((data as { meta: GetListDataPaginator })?.meta));

   		return (data?.data || []) as PromotionalOfferResource[];
  	}
);

/**
 * 取得精選優惠方案
 */
export const setFeaturedPromotionalOffers = createAction(
	'SET_FEATURED_PROMOTIONAL_OFFERS_LIST', 
	(featuredPromotionalOffers: PromotionalOfferResource[]) => featuredPromotionalOffers
);

/**
 * 取得優惠方案內頁資料
 */
export const getPromotionalOffersDataById = createAction<Promise<PromotionalOfferResource>, number>(
	'GET_PROMOTIONAL_OFFERS_DATA_BY_ID',
	async id => {
		const { v1PromotionalOfferDetail } = api;
		const { data, message } = await v1PromotionalOfferDetail(id);

		if (message !== 'success') {
			return {};
		}

		return data?.data as PromotionalOfferResource;
	},
);

export const setPaginationMetaData = createAction<GetListDataPaginator, GetListDataPaginator>(
	'SET_PAGINATION_META_DATA',
	paginationMetaData => paginationMetaData,
);

export interface State {
  loading: boolean;
  promotionalOffersList: PromotionalOfferResource[];
  featuredPromotionalOffersList: PromotionalOfferResource[];
	promotionalOffersData: PromotionalOfferResource,
	paginationMetaData?: GetListDataPaginator;
}

export const defaultState: State = {
	loading: false,
	promotionalOffersList: [],
	featuredPromotionalOffersList: [],
	promotionalOffersData: {},
	paginationMetaData: {},
};

export const reducer = {
	promotionalOffers: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PROMOTIONAL_OFFERS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_PROMOTIONAL_OFFERS_FULFILLED: (state: State, action: Action<PromotionalOfferResource[]>): State => ({
				...state,
				loading: false,
				promotionalOffersList: action.payload,
			}),

			SET_PAGINATION_META_DATA: (state: State, action: Action<GetListDataPaginator>): State => ({
				...state,
				paginationMetaData: action.payload,
			}),

			SET_FEATURED_PROMOTIONAL_OFFERS_LIST: (state: State, action: Action<PromotionalOfferResource[]>): State => ({
				...state,
				featuredPromotionalOffersList: action.payload,
			}),
			
			GET_PROMOTIONAL_OFFERS_DATA_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_PROMOTIONAL_OFFERS_DATA_BY_ID_FULFILLED: (state, action: Action<PromotionalOfferResource>) => ({
				...state,
				loading: false,
				promotionalOffersData: action.payload,
			}),
		},
		defaultState,
	),
};

const selectPromotionalOffers = (state: GlobalState) => ({
	promotionalOffersList: state.promotionalOffers.promotionalOffersList,
	featuredPromotionalOffersList: state.promotionalOffers.featuredPromotionalOffersList,
	promotionalOffersData: state.promotionalOffers.promotionalOffersData,
	paginationMetaData: state.promotionalOffers.paginationMetaData,
});

export const usePromotionalOffers = () => useRedux(selectPromotionalOffers, {
	getPromotionalOffers,
	getPromotionalOffersDataById,
});