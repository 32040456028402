import React, { useEffect } from 'react';
import classnames from 'classnames';

import { useModal, MODAL_CATEGORY } from 'models/modal';

import { useBoolean } from 'util/hook';
import { isExist } from 'util/helper';

import Icon from 'components/atoms/Icon';

import SuccessIcon from 'images/icon/toast-success.inline.svg';
import WarningIcon from 'images/icon/toast-warning.inline.svg';
import ErrorIcon from 'images/icon/toast-error.inline.svg';

import styles from './index.module.css';

const Toast: React.FC = () => {
	const [isShowToast, { setTrue: showToast, setFalse: hideToast }] = useBoolean({
		defaultBoolean: false,
	});
	const [
		{
			modal: {
				toast: { type, data },
			},
		},
		{ closeModal },
	] = useModal();

	useEffect(() => {
		if (isExist(type)) {
			showToast();
			setTimeout(() => hideToast(), 3000);
			setTimeout(() => closeModal(MODAL_CATEGORY.TOAST), 3500);
		}
	}, [type]);

	return (
		<div
			className={classnames(styles.toast, {
				[styles.open]: isShowToast,
				[styles.close]: !isShowToast,
			})}
		>
			<div className={classnames(styles.toastContainer, styles[type])}>
				{type === 'success' && <Icon src={SuccessIcon} />}
				{type === 'warning' && <Icon src={WarningIcon} />}
				{type === 'error' && <Icon src={ErrorIcon} />}
				<div className={styles.text}>{data}</div>
			</div>
		</div>
	);
};

export default Toast;
