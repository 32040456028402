import React, { MouseEvent } from 'react';
import qs from 'qs';

import { useHistory } from 'models/routing';
import { History } from 'history';

import classNames from 'classnames';
import styles from './index.css';

const onClickHandler = (callback: (e: MouseEvent<HTMLAnchorElement>) => void, history: History, search?: string) => (
	e: MouseEvent<HTMLAnchorElement>,
): void => {
	e.preventDefault();
	e.stopPropagation();
	history.push({
		pathname: e.currentTarget.pathname,
		search,
	});
	callback(e);
};


interface LinkProperty {
	className?: string;
	type?: 'internal' | 'external';
	to?: string;
	query?: { [key: string]: string };
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
	onMouseEnter?: (e: MouseEvent<HTMLAnchorElement>) => void;
	onMouseLeave?: (e: MouseEvent<HTMLAnchorElement>) => void;
	children: React.ReactNode;
}

const Link: React.FC<LinkProperty> = ({
	className,
	type = 'internal',
	to,
	query = {},
	onClick = () => {},
	onMouseEnter = () => {},
	onMouseLeave = () => {},
	children,
}) => {
	const history = useHistory();

	if (type === 'external') {
		return (
			<a
				className={classNames(styles.link, className)}
				href={to}
				target="_blank"
				rel="noopener noreferrer"
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{children}
			</a>
		);
	}

	const search = qs.stringify(query, { arrayFormat: 'brackets' });

	return (
		<a
			className={classNames(styles.link, className)}
			href={search === '' ? to : `${to}?${search}`}
			role="button"
			tabIndex={0}
			onClick={onClickHandler(onClick, history, search)}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onKeyPress={() => {}}
		>
			{children}
		</a>
	);
};

export default Link;
