import { combineReducers } from 'redux';

import * as home from './home';
import * as routing from './routing';
import * as user from './user';
import * as blogs from './blog';
import * as banners from './banner';
import * as tags from './tag';
import * as countries from './country';
import * as form from './form';
import * as tours from './tour';
import * as modal from './modal';
import * as auth from './auth';
import * as events from './events';
import * as travelBlog from './travelBlog';
import * as promotionalOffers from './promotionalOffers';
import * as global from './global';

// For Global State interface
export interface State {
	home: home.State;
	blogs: blogs.State;
	banners: banners.State;
	user: user.State;
	routing: routing.State;
	tags: tags.State;
	countries: countries.State;
	form: form.State;
	tours: tours.State;
	modal: modal.State;
	auth: auth.State;
	events: events.State;
	travelBlog: travelBlog.State;
	promotionalOffers: promotionalOffers.State;
	global: global.State;
}

export type GetState = () => State;

export const defaultState: State = {
	home: home.defaultState,
	blogs: blogs.defaultState,
	banners: banners.defaultState,
	user: user.defaultState,
	routing: routing.defaultState,
	tags: tags.defaultState,
	countries: countries.defaultState,
	form: form.defaultState,
	tours: tours.defaultState,
	modal: modal.defaultState,
	auth: auth.defaultState,
	events: events.defaultState,
	travelBlog: travelBlog.defaultState,
	promotionalOffers: promotionalOffers.defaultState,
	global: global.defaultState,
};

const reducers = combineReducers<State>({
	...home.reducer,
	...routing.reducer,
	...user.reducer,
	...blogs.reducer,
	...banners.reducer,
	...tags.reducer,
	...countries.reducer,
	...form.reducer,
	...tours.reducer,
	...modal.reducer,
	...auth.reducer,
	...events.reducer,
	...travelBlog.reducer,
	...promotionalOffers.reducer,
	...global.reducer,
});

export default reducers;
