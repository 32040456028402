import React, { useEffect, useState } from 'react';

import { useBoolean } from 'util/hook';
import { verifyCodeDeadLineDefaultValue } from 'util/constants';

import Modal from 'components/atoms/Modal';
import {
	SignUp,
	SignUpResult,
	Login,
	ForgotPassword,
	ResetPassword,
	VerifyCode,
} from 'components/molecules/ModalLoginSignUpInner';

import styles from './index.module.css';

interface ModalLoginSignUpProperty {
	step: string;
	setStep: React.Dispatch<React.SetStateAction<string>>;
	isOpen: boolean;
	closeModal: () => void;
	type:
		| 'login'
		| 'signUp'
		| 'signUpResultSuccess'
		| 'signUpResultFail'
		| 'forgotPassword'
		| 'resetPassword'
		| 'verifyCode';
}

interface ModalContentProperty {
	closeModal: () => void;
	step: string;
	previousStep: string;
	setStep: React.Dispatch<React.SetStateAction<string>>;
	setPreviousStep: React.Dispatch<React.SetStateAction<string>>;
	verifyCodeDeadline: number;
	setVerifyCodeDeadline: React.Dispatch<React.SetStateAction<number>>;
	setIsDeadlineStartTiming: () => void;
}

const ModalContent: React.FC<ModalContentProperty> = ({
	closeModal,
	step,
	setStep,
	previousStep,
	setPreviousStep,
	verifyCodeDeadline,
	setVerifyCodeDeadline,
	setIsDeadlineStartTiming,
}) => {
	switch (step) {
		case 'login':
			return <Login closeModal={closeModal} setStep={setStep} />;
		case 'signUp':
			return <SignUp setStep={setStep} setPreviousStep={setPreviousStep} />;
		case 'signUpResultSuccess':
			return <SignUpResult closeModal={closeModal} result="success" />;
		case 'signUpResultFail':
			return <SignUpResult closeModal={closeModal} result="fail" />;
		case 'forgotPassword':
			return <ForgotPassword setStep={setStep} setPreviousStep={setPreviousStep} />;
		case 'resetPassword':
			return <ResetPassword setStep={setStep} />;
		case 'verifyCode':
			return (
				<VerifyCode
					setStep={setStep}
					previousStep={previousStep}
					verifyCodeDeadline={verifyCodeDeadline}
					setVerifyCodeDeadline={setVerifyCodeDeadline}
					setIsDeadlineStartTiming={setIsDeadlineStartTiming}
				/>
			);
		default:
			break;
	}
	return null;
};

const ModalLoginSignUp: React.FC<ModalLoginSignUpProperty> = ({
	step,
	setStep,
	isOpen = false,
	closeModal,
	type,
}) => {
	const [previousStep, setPreviousStep] = useState('');
	const [verifyCodeDeadline, setVerifyCodeDeadline] = useState(verifyCodeDeadLineDefaultValue);
	const [
		isDeadlineStartTiming,
		{ setTrue: setIsDeadlineStartTiming, setFalse: unsetIsDeadlineStartTiming },
	] = useBoolean({
		defaultBoolean: false,
	});

	const stepTitle: { [key: string]: string } = {
		login: '登入',
		signUp: '註冊',
		signUpResultSuccess: '註冊',
		signUpResultFail: '註冊',
		forgotPassword: '忘記密碼',
		resetPassword: '重設密碼',
		verifyCode: 'Email 驗證',
	};

	useEffect(() => {
		setStep(type);
	}, [type]);

	useEffect(() => {
		if (isDeadlineStartTiming) {
			const interval = setInterval(() => {
				if (verifyCodeDeadline > 0) {
					setVerifyCodeDeadline(verifyCodeDeadline - 1);
				} else if (verifyCodeDeadline === 0) {
					clearInterval(interval);
					unsetIsDeadlineStartTiming();
				}
			}, 1000);

			return () => {
				clearInterval(interval);
			};
		}
		return () => {};
	}, [verifyCodeDeadline, isDeadlineStartTiming]);

	return (
		<Modal
			title={stepTitle[step]}
			modalContainerClassName={styles.modalContainer}
			canClickOutsideClose={false}
			isOpen={isOpen}
			closeModal={closeModal}
			contentBodyClassName={styles.modalBody}
		>
			<ModalContent
				closeModal={closeModal}
				step={step}
				setStep={setStep}
				previousStep={previousStep}
				setPreviousStep={setPreviousStep}
				verifyCodeDeadline={verifyCodeDeadline}
				setVerifyCodeDeadline={setVerifyCodeDeadline}
				setIsDeadlineStartTiming={setIsDeadlineStartTiming}
			/>
		</Modal>
	);
};

export default ModalLoginSignUp;
