import React, { ChangeEvent } from 'react';
import classnames from 'classnames';

import Icon from 'components/atoms/Icon';

import RadioDefault from 'images/icon/radio-default.inline.svg';
import RadioSelected from 'images/icon/radio-selected.inline.svg';

import styles from './index.module.css';

interface RadioProperty {
	className?: string;
	value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
	label: string;
	isSelected?: boolean;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Radio: React.FC<RadioProperty> = ({
	className,
	value = null,
	label = '',
	isSelected = false,
	onChange = () => {},
}) => {
	const handleClick = () => {
		onChange(value);
	};

	return (
		<div
			className={classnames(styles.radio, className)}
			onClick={handleClick}
			onKeyPress={() => {}}
			role="button"
			tabIndex={0}
		>
			<Icon
				className={styles.radioIcon}
				src={isSelected ? RadioSelected : RadioDefault}
				size="small"
			/>
			<label htmlFor={label}>
				<input type="radio" defaultChecked={isSelected} value={value} />
				{label}
			</label>
		</div>
	);
};

export default Radio;
