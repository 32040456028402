import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import { useBoolean } from 'util/hook';
import { navigationLinks } from 'util/links';

import { useAuth } from 'models/auth';
import { useGlobal } from 'models/global';

import { fixBackgroundScrollY, unfixBackgroundScrollY } from 'util/documentBackgroundScroll';

import Link from 'components/atoms/Link';
import MenuTitle from 'components/atoms/MenuTitle';
import Navigation from 'components/molecules/Navigation';
import ModalLoginSignUp from 'components/molecules/ModalLoginSignUp';
import ModalPrivacy from 'components/molecules/ModalPrivacy';

import CloseIcon from 'images/icon/close-circle.inline.svg';
import BackgroundLine from 'images/background/background-line-a.svg';

import styles from './index.module.css';

const Header: React.FC = () => {
	const [step, setStep] = useState('');
	const [{ isLogin }, { clearForm }] = useAuth();
	const [
		{ isNavMenuOpen, isMemberMenuOpen, isModalPrivacyOpen },
		{ setNavIsOpen, setNavClose, setMemberMenuOpen, setMemberMenuClose, setModalPrivacyClose },
	] = useGlobal();
	const [isModalSignUpOpen, { setTrue: modalSignUpOpen, setFalse: modalSignUpClose }] = useBoolean({
		defaultBoolean: false,
	});
	const [isModalLoginOpen, { setTrue: modalLoginOpen, setFalse: modalLoginClose }] = useBoolean({
		defaultBoolean: false,
	});

	const modalLoginSignUpType = () => {
		if (isModalSignUpOpen) {
			return 'signUp';
		}
		return 'login';
	};

	const isModalLoginSignUpOpen = () =>
		modalLoginSignUpType() === 'login' ? isModalLoginOpen : isModalSignUpOpen;
	const closeModalLoginSignUp = () =>
		modalLoginSignUpType() === 'login' ? modalLoginClose() : modalSignUpClose();

	useEffect(() => {
		if (isNavMenuOpen || isMemberMenuOpen || isModalLoginSignUpOpen()) {
			fixBackgroundScrollY();
		} else {
			unfixBackgroundScrollY();
		}
	}, [isNavMenuOpen, isMemberMenuOpen]);

	useEffect(() => {
		if (!isModalSignUpOpen && isModalPrivacyOpen) {
			fixBackgroundScrollY();
		} else if (!isModalSignUpOpen && !isModalPrivacyOpen){
			unfixBackgroundScrollY();
		}
	}, [isModalPrivacyOpen]);

	return (
		<>
			<header
				className={classnames(styles.header, {
					[styles.menuOpen]: isNavMenuOpen || isMemberMenuOpen,
				})}
			>
				<Navigation
					isMenuOpen={isNavMenuOpen}
					handleMenuOpen={(open: boolean) => setNavIsOpen(open)}
					isMemberMenuOpen={isMemberMenuOpen}
					memberMenuOpen={setMemberMenuOpen}
					memberMenuClose={setMemberMenuClose}
					modalSignUpOpen={modalSignUpOpen}
					modalLoginOpen={modalLoginOpen}
				/>
			</header>

			{isNavMenuOpen && (
				<div className={styles.menuWrapper}>
					<div
						className={styles.close}
						role="button"
						onClick={() => setNavClose()}
						onKeyDown={() => setNavClose()}
						tabIndex={0}
					>
						<CloseIcon />
					</div>
					<div className={styles.menu}>
						<div
							className={styles.background}
							style={{ backgroundImage: `url(${BackgroundLine})` }}
						/>
						<div className={styles.contentWrapper}>
							<MenuTitle title="MENU" />
							<ul className={styles.menuList}>
								{navigationLinks.map(link => (
									<li>
										<Link className={styles.link} onClick={() => setNavClose()} to={link.path}>
											{link.label}
										</Link>
									</li>
								))}
							</ul>
							{!isLogin && (
								<div className={styles.loginSection}>
									<button
										type="button"
										className={styles.link}
										onClick={() => {
											clearForm('signUpForm');
											modalSignUpOpen();
											setNavClose();
										}}
									>
										註冊
									</button>
									<span>|</span>
									<button
										type="button"
										className={styles.link}
										onClick={() => {
											clearForm('loginForm');
											modalLoginOpen();
											setNavClose();
										}}
									>
										登入
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			)}

			<ModalLoginSignUp
				step={step}
				setStep={setStep}
				type={modalLoginSignUpType()}
				isOpen={isModalLoginSignUpOpen()}
				closeModal={() => {
					setStep(modalLoginSignUpType());
					closeModalLoginSignUp();
				}}
			/>
			{isModalPrivacyOpen && (
				<ModalPrivacy isOpen={isModalPrivacyOpen} closeModal={setModalPrivacyClose} />
			)}
		</>
	);
};

export default Header;
