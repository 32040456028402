import { createAction, handleActions } from 'redux-actions';

import { useRedux } from 'util/hook/redux';

import { State as GlobalState } from './reducers';

const setNavOpen = createAction('SET_NAV_OPEN');
const setNavIsOpen = createAction('SET_NAV_IS_OPEN');
const setNavClose = createAction('SET_NAV_CLOSE');
const setMemberMenuOpen = createAction('SET_MEMBER_MENU_OPEN');
const setMemberMenuClose = createAction('SET_MEMBER_MENU_CLOSE');
const setTooltipActive = createAction('SET_TOOLTIP_ACTIVE');
const setModalPrivacyOpen = createAction('SET_MODAL_PRIVACY_OPEN');
const setModalPrivacyIsOpen = createAction('SET_MODAL_PRIVACY_IS_OPEN');
const setModalPrivacyClose = createAction('SET_MODAL_PRIVACY_CLOSE');

export interface State {
	isNavMenuOpen: boolean;
	isMemberMenuOpen: boolean;
  isTooltipOpen: boolean;
	activeTooltipId: number | null;
	isModalPrivacyOpen: boolean;
}

export const defaultState: State = {
	isNavMenuOpen: false,
	isMemberMenuOpen: false,
	isTooltipOpen: false,
	activeTooltipId: null,
	isModalPrivacyOpen: false,
};

export const reducer = {
	global: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_NAV_OPEN: state => ({
				...state,
				isNavMenuOpen: true,
			}),

			SET_NAV_IS_OPEN: state => ({
				...state,
				isNavMenuOpen: !state.isNavMenuOpen,
			}),

			SET_NAV_CLOSE: state => ({
				...state,
				isNavMenuOpen: false,
			}),
			
			SET_MEMBER_MENU_OPEN: state => ({	
				...state,
				isMemberMenuOpen: true,
			}),

			SET_MEMBER_MENU_CLOSE: state => ({
				...state,
				isMemberMenuOpen: false,
			}),

			SET_TOOLTIP_ACTIVE: (state, { payload }) => ({
			  ...state,
			  activeTooltipId: payload,
			}),

			SET_MODAL_PRIVACY_OPEN: state => ({
				...state,
				isModalPrivacyOpen: true,
			}),

			SET_MODAL_PRIVACY_IS_OPEN: state => ({
				...state,
				isModalPrivacyOpen: !state.isModalPrivacyOpen,
			}),

			SET_MODAL_PRIVACY_CLOSE: state => ({
				...state,
				isModalPrivacyOpen: false,
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => state.global;

const globalActionsMap = {
	setNavOpen,
	setNavIsOpen,
	setNavClose,
	setMemberMenuOpen,
	setMemberMenuClose,
	setTooltipActive,
	setModalPrivacyOpen,
	setModalPrivacyIsOpen,
	setModalPrivacyClose,
};

type GlobalSelector = ReturnType<typeof mapHooksToState>;
type GlobalActionsMap = typeof globalActionsMap;

export const useGlobal = () =>
	useRedux<GlobalSelector, GlobalActionsMap>(mapHooksToState, globalActionsMap);
