import React from 'react';
import classnames from 'classnames';

import StarIcon from 'images/icon/star.inline.svg';

import styles from './index.module.css';

interface MenuTitleProperty {
	className?: string;
	title: string;
}

const MenuTitle: React.FC<MenuTitleProperty> = ({ className, title }) => (
	<div className={classnames(styles.menuTitle, className)}>
		<span className={styles.starLeft}>
			<StarIcon />
		</span>
		<p className={styles.title}>{title}</p>
		<span className={styles.starRight}>
			<StarIcon />
		</span>
	</div>
);

export default MenuTitle;
