import React, { useEffect } from 'react';
import classnames from 'classnames';

import { useMedia } from 'util/hook/useMedia';
import { validatePhone, validateEmail, validatePassword } from 'util/helper';
import { verifyCodeDeadLineDefaultValue } from 'util/constants';

import { useAuth } from 'models/auth';
import { useForm } from 'models/form';
import { useGlobal } from 'models/global';

import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Link from 'components/atoms/Link';
import Checkbox from 'components/atoms/Checkbox';
import ReminderPrompt from 'components/atoms/ReminderPrompt';
import VerificationCodeInput from 'components/atoms/VerificationCodeInput';
import RadioGroup from 'components/molecules/RadioGroup';

import SignUpSuccess from 'images/sign-up-success.inline.svg';
import SignUpFail from 'images/sign-up-fail.inline.svg';

import styles from './index.module.css';

interface ModalContentProperty {
	setStep: React.Dispatch<React.SetStateAction<string>>;
}

interface VerifyCodeProperty extends ModalContentProperty {
	previousStep: string;
	verifyCodeDeadline: number;
	setVerifyCodeDeadline: (newValue: number) => void;
	setIsDeadlineStartTiming: () => void;
}

export const SignUp: React.FC<
	ModalContentProperty & {
		setPreviousStep: React.Dispatch<React.SetStateAction<string>>;
	}
> = ({ setStep, setPreviousStep }) => {
	const [{ signUpForm }, { updateAuthForm, checkUserEmail }] = useAuth();
	const [, { setModalPrivacyOpen }] = useGlobal();

	const clearFormErrorMessage = () => {
		Object.keys(signUpForm).forEach(k =>
			updateAuthForm({
				type: 'signUpForm',
				key: k,
				data: { ...signUpForm[k], error: '' },
			}),
		);
	};

	const validateUserInfoForm = () => {
		let valid = true;
		clearFormErrorMessage();

		if (!validateEmail(signUpForm.email.value)) {
			updateAuthForm({
				type: 'signUpForm',
				key: 'email',
				data: { value: signUpForm.email.value, error: '電子信箱格式不正確' },
			});
			valid = false;
		}

		if (!validatePassword(signUpForm.password.value)) {
			updateAuthForm({
				type: 'signUpForm',
				key: 'password',
				data: { value: signUpForm.password.value, error: '密碼格式錯誤，請重新確認' },
			});
			valid = false;
		}

		if (!validatePhone(signUpForm.mobile.value)) {
			updateAuthForm({
				type: 'signUpForm',
				key: 'mobile',
				data: { value: signUpForm.mobile.value, error: '手機號碼格式不正確' },
			});
			valid = false;
		}

		return valid;
	};

	const handleSubmit = () => {
		if (!validateUserInfoForm()) return;
		checkUserEmail({ checkType: 'register', setPreviousStep, setStep });
	};

	return (
		<div className={classnames(styles.innerWrapper, styles.registerWrapper)}>
			<Input
				className={styles.input}
				labelText="Email"
				placeholder="請輸入您的 Email"
				required
				value={signUpForm.email?.value}
				error={signUpForm.email?.error}
				onChange={e => {
					updateAuthForm({
						type: 'signUpForm',
						key: 'email',
						data: { value: e.target.value },
					});
				}}
			/>
			<Input
				className={styles.input}
				type="password"
				isPassword
				hasPasswordReminder
				labelText="密碼"
				placeholder="請輸入您的密碼"
				required
				value={signUpForm.password?.value}
				error={signUpForm.password?.error}
				onChange={e => {
					if (e.target.value.length > 20) return;

					updateAuthForm({
						type: 'signUpForm',
						key: 'password',
						data: { value: e.target.value },
					});
				}}
			/>
			<Input
				className={styles.input}
				labelText="姓名"
				placeholder="請輸入您的真實姓名"
				required
				value={signUpForm.name?.value}
				onChange={e => {
					updateAuthForm({
						type: 'signUpForm',
						key: 'name',
						data: { value: e.target.value },
					});
				}}
			/>
			<Input
				className={styles.input}
				type="text"
				labelText="手機號碼"
				placeholder="請輸入您的手機號碼"
				maxLength={10}
				required
				value={signUpForm.mobile?.value}
				error={signUpForm.mobile?.error}
				onChange={e => {
					updateAuthForm({
						type: 'signUpForm',
						key: 'mobile',
						data: { value: e.target.value },
					});
				}}
			/>
			<RadioGroup
				className={classnames(styles.input, styles.radioGroup)}
				label="為提供更好的服務，您是否同意我們取用您過往的行程資料"
				required
				data={[
					{ value: true, label: '同意' },
					{ value: false, label: '不同意' },
				]}
				value={signUpForm.isParticipated.value}
				onChange={([v]) =>
					updateAuthForm({ type: 'signUpForm', key: 'isParticipated', data: { value: v.value } })
				}
			/>
			<Button
				className={styles.button}
				size="large"
				fullWidth
				disabled={
					!signUpForm.email?.value ||
					!signUpForm.password?.value ||
					!signUpForm.name?.value ||
					!signUpForm.mobile?.value
				}
				onClick={handleSubmit}
			>
				註冊
			</Button>
			<div className={styles.loginWrapper}>
				已有帳號嗎？
				<button
					type="button"
					className={styles.link}
					onClick={() => {
						setStep('login');
					}}
				>
					點此進行登入
				</button>
			</div>
			<div className={styles.policyWrapper}>
				註冊即代表您同意我們的
				<button type="button" className={styles.link} onClick={setModalPrivacyOpen}>
					隱私權政策與會員服務條款
				</button>
			</div>
		</div>
	);
};

export const SignUpResult: React.FC<{ result: 'success' | 'fail'; closeModal: () => void }> = ({
	result,
	closeModal,
}) => (
	<div className={classnames(styles.innerWrapper, styles.registerResultWrapper)}>
		{result === 'success' ? (
			<>
				<div className={styles.image}>
					<SignUpSuccess />
				</div>
				<p className={styles.title}>註冊成功！</p>
				<p>
					立即設定&nbsp;
					<Link className={styles.link} to="/member/profile" onClick={closeModal}>
						會員資料
					</Link>
				</p>
			</>
		) : (
			<>
				<div className={styles.image}>
					<SignUpFail />
				</div>
				<p className={styles.title}>註冊失敗！</p>
				<p>請再試一次</p>
			</>
		)}
	</div>
);

export const Login: React.FC<ModalContentProperty & { closeModal: () => void }> = ({
	closeModal,
	setStep,
}) => {
	const [{ loginForm }, { updateAuthForm, login, clearForm }] = useAuth();

	return (
		<div className={classnames(styles.innerWrapper, styles.loginWrapper)}>
			<Input
				className={styles.input}
				labelText="Email"
				placeholder="請輸入您的 Email"
				required
				value={loginForm.email?.value}
				error={loginForm.email?.error}
				onChange={e => {
					updateAuthForm({
						type: 'loginForm',
						key: 'email',
						data: { value: e.target.value },
					});
				}}
			/>
			<Input
				className={styles.input}
				type="password"
				isPassword
				labelText="密碼"
				placeholder="請輸入您的密碼"
				required
				value={loginForm.password?.value}
				error={loginForm.password?.error}
				onChange={e => {
					updateAuthForm({
						type: 'loginForm',
						key: 'password',
						data: { value: e.target.value },
					});
				}}
			/>
			<div className={styles.checkbox}>
				<Checkbox
					isChecked={loginForm.remember.value}
					onChange={() =>
						updateAuthForm({
							type: 'loginForm',
							key: 'remember',
							data: { value: !loginForm.remember.value },
						})
					}
				>
					記住我
				</Checkbox>
				<button
					type="button"
					className={styles.link}
					onClick={() => {
						clearForm('forgotPasswordForm');
						clearForm('resetPasswordForm');
						setStep('forgotPassword');
					}}
				>
					忘記密碼
				</button>
			</div>
			<Button
				className={styles.button}
				size="large"
				fullWidth
				disabled={!loginForm.email?.value || !loginForm.password?.value}
				onClick={() => {
					login({ closeModal });
				}}
			>
				登入
			</Button>
			<div className={styles.registerWrapper}>
				還沒有帳號嗎？
				<button
					type="button"
					className={styles.link}
					onClick={() => {
						setStep('signUp');
						clearForm('signUpForm');
					}}
				>
					點此進行註冊
				</button>
			</div>
		</div>
	);
};

export const ForgotPassword: React.FC<
	ModalContentProperty & { setPreviousStep: React.Dispatch<React.SetStateAction<string>> }
> = ({ setStep, setPreviousStep }) => {
	const [{ forgotPasswordForm }, { updateAuthForm, checkUserEmail }] = useAuth();

	const validateUserInfoForm = () => {
		let valid = true;

		// 清除 error message
		updateAuthForm({
			type: 'forgotPasswordForm',
			key: 'email',
			data: { ...forgotPasswordForm, error: '' },
		});

		if (!validateEmail(forgotPasswordForm.email.value)) {
			updateAuthForm({
				type: 'forgotPasswordForm',
				key: 'email',
				data: { value: forgotPasswordForm.email.value, error: '電子信箱格式不正確' },
			});
			valid = false;
		}

		return valid;
	};

	const handleSubmit = () => {
		if (!validateUserInfoForm()) return;
		checkUserEmail({ checkType: 'reset-password', setPreviousStep, setStep });
	};

	return (
		<div className={classnames(styles.innerWrapper, styles.forgotPasswordWrapper)}>
			<p>請輸入 Email 來重設密碼</p>
			<Input
				className={styles.input}
				labelText="Email"
				placeholder="請輸入您的 Email"
				required
				value={forgotPasswordForm.email?.value}
				error={forgotPasswordForm.email?.error}
				onChange={e => {
					updateAuthForm({
						type: 'forgotPasswordForm',
						key: 'email',
						data: { value: e.target.value },
					});
				}}
			/>
			<Button
				className={styles.button}
				size="large"
				fullWidth
				disabled={!forgotPasswordForm.email?.value}
				onClick={handleSubmit}
			>
				確認
			</Button>
			<Button
				className={styles.buttonBack}
				size="large"
				fullWidth
				outline
				onClick={() => {
					setStep('login');
				}}
			>
				返回
			</Button>
		</div>
	);
};

export const ResetPassword: React.FC<ModalContentProperty> = ({ setStep }) => {
	const [{ resetPasswordForm }, { updateAuthForm, resetPassword }] = useAuth();

	const validatePasswordForm = () => {
		let valid = true;

		// 清除 error message
		updateAuthForm({
			type: 'resetPasswordForm',
			key: 'confirmPassword',
			data: { ...resetPasswordForm, error: '' },
		});

		if (!validatePassword(resetPasswordForm.password.value)) {
			updateAuthForm({
				type: 'resetPasswordForm',
				key: 'confirmPassword',
				data: { value: resetPasswordForm.confirmPassword.value, error: '密碼格式錯誤，請重新確認' },
			});
			valid = false;
		}

		if (resetPasswordForm.password.value !== resetPasswordForm.confirmPassword.value) {
			updateAuthForm({
				type: 'resetPasswordForm',
				key: 'confirmPassword',
				data: {
					value: resetPasswordForm.confirmPassword.value,
					error: '與新密碼不同，請確認後再試一次',
				},
			});
			valid = false;
		}

		return valid;
	};

	return (
		<div className={classnames(styles.innerWrapper, styles.resetPasswordWrapper)}>
			<ReminderPrompt className={styles.reminderPrompt} text="請設定 8~20 位數的英數字混合密碼" />
			<Input
				className={styles.input}
				type="password"
				isPassword
				labelText="密碼"
				placeholder="請輸入您的密碼"
				required
				value={resetPasswordForm.password?.value}
				onChange={e => {
					if (e.target.value.length > 20) return;

					updateAuthForm({
						type: 'resetPasswordForm',
						key: 'password',
						data: { value: e.target.value },
					});
				}}
			/>
			<Input
				className={styles.input}
				type="password"
				isPassword
				labelText="確認新密碼"
				placeholder="請再次輸入您的新密碼"
				required
				value={resetPasswordForm.confirmPassword?.value}
				error={resetPasswordForm.confirmPassword?.error}
				onChange={e => {
					if (e.target.value.length > 20) return;

					updateAuthForm({
						type: 'resetPasswordForm',
						key: 'confirmPassword',
						data: { value: e.target.value },
					});
				}}
			/>
			<Button
				className={styles.button}
				size="large"
				fullWidth
				disabled={
					resetPasswordForm.password.value.length < 8 ||
					resetPasswordForm.confirmPassword.value.length < 8
				}
				onClick={() => {
					if (!validatePasswordForm()) return;
					resetPassword({ setStep });
				}}
			>
				重設密碼
			</Button>
		</div>
	);
};

export const VerifyCode: React.FC<VerifyCodeProperty> = ({
	setStep,
	previousStep,
	verifyCodeDeadline,
	setVerifyCodeDeadline,
	setIsDeadlineStartTiming,
}) => {
	const media = useMedia();
	const [{ signUpForm, forgotPasswordForm }] = useAuth();
	const [{ verifyCode }, { updateVerifyCodeForm, sendVerifyCode, submitVerifyCode }] = useForm();

	// 寄出驗證信並倒數 120 秒
	const handleClickSendVerifiedCode = () => {
		setVerifyCodeDeadline(verifyCodeDeadLineDefaultValue);
		setIsDeadlineStartTiming();
		sendVerifyCode({
			email: previousStep === 'signUp' ? signUpForm.email.value : forgotPasswordForm.email.value,
			verifyType: previousStep === 'signUp' ? 'member-register' : 'member-resetPassword',
		});
	};

	useEffect(() => {
		if (verifyCodeDeadline === 0) {
			setVerifyCodeDeadline(verifyCodeDeadLineDefaultValue);
		}
	}, []);

	useEffect(() => {
		if (verifyCodeDeadline === verifyCodeDeadLineDefaultValue) {
			setIsDeadlineStartTiming();
		}
	}, [verifyCodeDeadline]);

	return (
		<div className={classnames(styles.innerWrapper, styles.verifyCodeWrapper)}>
			<p>
				請輸入發送到{' '}
				<span>
					{previousStep === 'signUp' ? signUpForm.email.value : forgotPasswordForm.email.value}
				</span>{' '}
				的 6 位驗證碼
			</p>
			<div className={classnames(styles.verifyCodeInput, { [styles.hasError]: verifyCode?.error })}>
				<VerificationCodeInput
					size={media === 'mobile' ? 'small' : 'normal'}
					error={verifyCode.error !== ''}
					onChange={e => {
						updateVerifyCodeForm({ value: e, error: '' });
					}}
				/>
				{verifyCode?.error && <div className={styles.error}>{verifyCode?.error}</div>}
			</div>
			{verifyCodeDeadline !== 0 ? (
				<Button outline disabled={verifyCodeDeadline !== 0}>
					重新發送驗證信{' '}
					<span>{`（${Math.floor(verifyCodeDeadline).toString().padStart(2, '0')}s）`}</span>
				</Button>
			) : (
				<Button outline onClick={handleClickSendVerifiedCode}>
					重新發送驗證碼
				</Button>
			)}
			<Button
				className={styles.button}
				size="large"
				fullWidth
				disabled={!!(verifyCode.value.length < 6)}
				onClick={() => {
					submitVerifyCode({
						email:
							previousStep === 'signUp' ? signUpForm.email.value : forgotPasswordForm.email.value,
						verifyType: previousStep === 'signUp' ? 'member-register' : 'member-resetPassword',
						setStep,
					});
				}}
			>
				確認
			</Button>
			<Button
				className={styles.buttonBack}
				size="large"
				fullWidth
				outline
				onClick={() => {
					setStep(previousStep);
				}}
			>
				返回
			</Button>
		</div>
	);
};
