import { useState, useEffect } from 'react';

export type Media = 'mobile' | 'tablet' | 'desktop';

const MEDIA_QUERIES: Record<Media, string> = {
	mobile: '(max-width: 767px)',
	tablet: '(max-width: 1279px) and (min-width: 768px)',
	desktop: '(min-width: 1280px)',
};

export const useMedia = (): Media => {
	const getInitialMedia = (): Media =>
		(Object.keys(MEDIA_QUERIES) as Media[]).find(
			mediaType => window.matchMedia(MEDIA_QUERIES[mediaType]).matches,
		) || 'desktop';

	const [media, setMedia] = useState<Media>(getInitialMedia);

	useEffect(() => {
		const handlers: Record<Media, (event: MediaQueryListEvent) => void> = {
			mobile: event => event.matches && setMedia('mobile'),
			tablet: event => event.matches && setMedia('tablet'),
			desktop: event => event.matches && setMedia('desktop'),
		};

		const mediaListeners = Object.entries(MEDIA_QUERIES).map(([mediaType, query]) => {
			const mql = window.matchMedia(query);
			mql.addListener(handlers[mediaType as Media]);
			return { mediaQueryList: mql, handler: handlers[mediaType as Media] };
		});

		return () => {
			mediaListeners.forEach(({ mediaQueryList, handler }) => {
				mediaQueryList.removeListener(handler);
			});
		};
	}, []);

	return media;
};
