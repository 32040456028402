import React from 'react';
import classnames from 'classnames';

import CheckboxIcon from 'images/icon/checkbox-default.inline.svg';
import CheckedIcon from 'images/icon/checkbox-checked.inline.svg';

import styles from './index.module.css';

interface CheckboxProperty {
	id?: string;
	isChecked?: boolean;
	className?: string;
	childrenClassName?: string;
	value?: string;
	onChange?: (checked: boolean) => void;
	children: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProperty> = ({
	id,
	isChecked = false,
	className,
	childrenClassName = '',
	value,
	onChange = () => {},
	children,
}) => (
	<label className={classnames(styles.label, className)} htmlFor={id}>
		<div className={styles.checkbox}>
			<input
				type="checkbox"
				id={id}
				value={value}
				onChange={() => onChange(!isChecked)}
				checked={isChecked}
			/>
			{isChecked ? <CheckedIcon /> : <CheckboxIcon />}
		</div>
		{children && <div className={classnames(styles.container, childrenClassName)}>{children}</div>}
	</label>
);

export default Checkbox;
