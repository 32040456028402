import React from 'react';

import { useMedia } from 'util/hook/useMedia';

import Link from 'components/atoms/Link';
import Divider from 'components/atoms/Divider';

import { useGlobal } from 'models/global';

import LogoLarge from 'images/icon/logo-footer.svg';
import LogoSmall from 'images/icon/logo-footer-mobile.svg';
import PhoneIcon from 'images/icon/phone.inline.svg';
import IgIcon from 'images/icon/ig-footer.inline.svg';
import FaceBookIcon from 'images/icon/facebook-footer.inline.svg';
import YoutubeIcon from 'images/icon/youtube-footer.inline.svg';
import LineIcon from 'images/icon/line-footer.inline.svg';

import styles from './index.module.css';

const Footer: React.FC = () => {
	const [, { setModalPrivacyOpen }] = useGlobal();
	const media = useMedia();
	const data = [
		{
			id: 1,
			title: '台北總公司',
			address: '台北市敦化南路二段 76 號 8 樓',
			phone: '02-6638-8888',
		},
		{
			id: 2,
			title: '台中分公司',
			address: '台中市西區大隆路 20 號 11 樓之 8',
			phone: '04-2328-2198',
		},
		{
			id: 3,
			title: '高雄分公司',
			address: '高雄市鼓山區中華一路 820 號 10 樓',
			phone: '07-553-3111',
		},
	];

	return (
		<footer className={styles.footerWrapper}>
			<Divider type="bottom" className={styles.divider} />
			<div className={styles.footer}>
				{media === 'mobile' ? (
					<img src={LogoSmall} alt="logo" />
				) : (
					<img src={LogoLarge} alt="logo" />
				)}
				<div className={styles.contentWrapper}>
					<ul className={styles.addressWrapper}>
						{data.map(item => (
							<li key={item.id}>
								<div>
									<p className={styles.title}>{item.title}</p>
									<p className={styles.address}>{item.address}</p>
									<p className={styles.phone}>
										<PhoneIcon />
										&nbsp;{item.phone}
									</p>
								</div>
								{media !== 'mobile' && <span className={styles.divideLine} />}
							</li>
						))}
					</ul>
					<div className={styles.contact}>
						<p className={styles.title}>聯絡我們</p>
						<div className={styles.social}>
							<a href="https://www.instagram.com/mitravel_tw/" target="_blank" rel="noreferrer">
								<IgIcon />
							</a>
							<a href="https://www.facebook.com/mitravel" target="_blank" rel="noreferrer">
								<FaceBookIcon />
							</a>
							<a
								href="https://www.youtube.com/channel/UC82nlqvMxgVrkpTlulUVx3A"
								target="_blank"
								rel="noreferrer"
							>
								<YoutubeIcon />
							</a>
							<a href="https://lin.ee/wkcAObL" target="_blank" rel="noreferrer">
								<LineIcon />
							</a>
						</div>
					</div>
				</div>

				<div className={styles.bottom}>
					<p className={styles.copyRight}>Copyright © MITRAVEL All Rights Reserved.</p>
					<div className={styles.links}>
						<Link to="" onClick={setModalPrivacyOpen}>隱私權保護政策與使用約定條款</Link>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
