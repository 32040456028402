import React from 'react';
import classnames from 'classnames';

import Icon from 'components/atoms/Icon';

import InfoIcon from 'images/icon/info.inline.svg';

import styles from './index.module.css';

interface ReminderPromptProperty {
	className?: string;
	text?: string;
}

const ReminderPrompt: React.FC<ReminderPromptProperty> = ({ className, text }) => (
	<div className={classnames(styles.reminderPrompt, className)}>
		<Icon className={styles.infoIcon} src={InfoIcon} size="small" />
		{text}
	</div>
);

export default ReminderPrompt;
