import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';

import { BannerResource } from 'util/api/swaggerApi/data-contracts';
import { State as GlobalState } from './reducers';

export const getBanners = createAction<Promise<BannerResource[]>>('GET_BANNERS', async () => {
	const { v1BannerList } = api;
	const { data, status } = await v1BannerList();

	if (status !== 200 && status !== 201) {
		return [];
	}

	return data?.data as BannerResource[];
});

export interface State {
	loading: boolean;
	banners: BannerResource[];
}

export const defaultState: State = {
	loading: false,
	banners: [],
};

export const reducer = {
	banners: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_BANNERS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_BANNERS_FULFILLED: (state, action: Action<BannerResource[]>) => ({
				...state,
				banners: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const bannerActionsMap = {
	getBanners,
};

const mapHooksToState = (state: GlobalState) => ({
	banners: state.banners.banners,
});

type BannerSelector = ReturnType<typeof mapHooksToState>;
type BannerActionsMap = typeof bannerActionsMap;

export const useBanner = () =>
	useRedux<BannerSelector, BannerActionsMap>(mapHooksToState, bannerActionsMap);
