import { useRef, useEffect, RefObject } from 'react';

const useEventListener = <T extends HTMLElement = HTMLDivElement>(
	eventName: string,
	handler: (event: Event) => void,
	element?: RefObject<T>,
) => {
	// Create a ref that stores handler
	const savedHandler = useRef<(event: Event) => void>();

	useEffect(() => {
		// Define the listening target
		const targetElement: T | Window = element?.current || window;

		// Update saved handler if necessary
		if (savedHandler.current !== handler) {
			savedHandler.current = handler;
		}

		// Create event listener that calls handler function stored in ref
		const eventListener = (event: Event) => {
			// eslint-disable-next-line no-extra-boolean-cast
			if (!!savedHandler?.current) {
				savedHandler.current(event);
			}
		};

		targetElement.addEventListener(eventName, eventListener);

		// Remove event listener on cleanup
		return () => {
			targetElement.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element, handler]);
};

export default useEventListener;
