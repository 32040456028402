import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getTagList } from 'models/tag';
import { getEvents, getEventDataById } from 'models/events';

const routes: CustomRoute = {
	path: '/events',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Events]) => <Events />,
			onEnter: async ({ store, next }) => {
				const children = await next();
				store.dispatch(getEvents({ is_featured: true, page: 1 }));
				return children;
			},
		},
		{
			path: '/:id',
			components: () => [import('./EventDetail/component')],
			render: ([EventDetail]) => <EventDetail />,
			onEnter: async ({ store, params }) => {
				const id = parseInt(params.id as string, 10);
				if (!Number.isNaN(id)) {
					await store.dispatch(getEventDataById(id));
				}
			},
		},
		{
			path: '/tag/:tag',
			components: () => [import('./EventTagFiltered/component')],
			render: ([EventTagFiltered]) => <EventTagFiltered />,
			onEnter: async ({ store, params }) => {
				const { tag } = params;
				await store.dispatch(getTagList());
				await store.dispatch(getEvents({ tags: tag as string, page: 1 }));
			},
		},
	],
};

export default routes;
