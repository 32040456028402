import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { authApi } from 'util/api';
import {
	UserResource,
	FormContactResource,
	FormRegisterResource,
	FormEventResource,
	GetListDataPaginator
} from 'util/api/swaggerApi/data-contracts';

import { openModal, TOAST_TYPE, MODAL_CATEGORY } from 'models/modal';

import { GetState, State as GlobalState } from './reducers';

interface FormItem {
	value: HTMLInputElement['value'];
	error?: string;
}

interface FormPayload {
	key: string;
	value: string;
	error?: string;
}

interface UserFormProperty {
	name: FormItem;
	nickname: FormItem;
	mobile: FormItem;
	email: FormItem;
	identityNumber: FormItem;
	birthday: FormItem;
}

/**
 * 取得會員資料
 */
export const getUser = createAction<Promise<UserResource>>(
	'GET_USER',
	async () => {
		const { v1UserList } = authApi;
		const { data, message } = await v1UserList();

		if (message !== 'success') {
			return {};
		}

		return data?.data as UserResource;
	},
);

type OrderKind = 'contacts' | 'registers' | 'events';
type OrderStatus = "處理中" | "已聯繫" | "已完成" | undefined;
type OrderResponseData = (FormContactResource | FormRegisterResource | FormEventResource)[];

/**
 * 取得會員訂單列表
 * @param type 訂單類型
 * @param status 訂單狀態
 * @param page 頁碼
 */
export const getOrderListByUser = createAction<
	(dispatch: Dispatch) => Promise<OrderResponseData>, OrderKind, OrderStatus, number>(
		'GET_ORDER_LIST_BY_USER',
	(type, status = undefined, page = 1) => async dispatch => {
		const { v1UserFormDetail } = authApi;
		const { data, message } = await v1UserFormDetail({
			type,
			status,
			page,
		});

		if (message !== 'success') {
			return [];
		}

		dispatch(setPaginationMetaData((data as { meta: GetListDataPaginator })?.meta));

		// type === registers 時資料格式會不一樣，需要特別處理
		if (type === 'registers') {
			dispatch(
				setRegisterRecordList(
					(data?.data as unknown as { registerRecord: FormRegisterResource['registerRecord'] })?.registerRecord)
			);

			return (data?.data as unknown as { form: FormRegisterResource[] })?.form
		}

		return data?.data as unknown as OrderResponseData;
	});


/**
 * 會員資料表單初始化，自動帶入會員資料
 */
export const initUserForm = createAction<(dispatch: Dispatch, getState: GetState) => Promise<void>>(
	'INIT_USER_FORM',
() => async (dispatch, getState) => {
	const {
		user: { userInfo },
	} = getState();

	Object.keys(userInfo).forEach(key => {
		if (key === 'id') {
			return;
		};

		dispatch(updateUserForm({
			key,
			value: userInfo[key as keyof UserResource] as string,
		}));
	});
},
)

/**
 * 更新會員資料表單
 */
export const updateUserForm = createAction(
	'UPDATE_USER_FORM',
	({ key, value, error }: FormPayload) => ({
		key,
		value: (value || '').trim(),
		error: error || '',
	}),
);

/**
 * 提交會員資料表單
 */
export const submitUpdateUserForm = createAction<(dispatch: Dispatch, getState: GetState) => Promise<void>>(
	'SUBMIT_UPDATE_USER_FORM',
() => async (dispatch, getState) => {
	const {
		user: {
			userForm: {
				name,
				nickname,
				mobile,
				identityNumber,
				birthday,
			},
		}
	} = getState();

	try {
		const { v1UserUpdate } = authApi;
		const { status } = await v1UserUpdate({
			name: name.value,
			nickname: nickname.value,
			mobile: mobile.value,
			identity_number: identityNumber.value,
			birthday: birthday.value,
		});

		if (status === 200) {
			dispatch(
				openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '個人資料更新成功' }),
			);
		}
	} catch (error) {
		dispatch(
			openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.ERROR, data: '個人資料更新失敗，請稍後再試' }),
		);
	}
},
);

/**
 * 更新會員信箱
 */
export const submitUpdateUserEmail = createAction(
	'SUBMIT_UPDATE_USER_EMAIL',
	({ closeModal } : { closeModal: () => void }) => 
		async (dispatch: Dispatch, getState: GetState): Promise<void> => {
			const {
				user: {
					userForm: { email },
				},
				auth: { authToken },
			} = getState();

			const requestParams = {
				headers: {
					'X-User-Mail-Check-Token': authToken.checkEmail,
					'X-VerifyCode-Token': authToken.verifyCode,
				} as Record<string, string>
			};

			try {
				const { v1UserEmailUpdate } = authApi;
				await v1UserEmailUpdate(
					{ email: email.value },
					requestParams
				);
				dispatch(
					openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: 'Email 變更成功' }),
				);
				dispatch(closeModal);
				dispatch(getUser());
			} catch (e) {
				console.log(`error: ${(e as { error?: { message?: string } }).error?.message}`);
			}
		});

export const setPaginationMetaData = createAction<GetListDataPaginator, GetListDataPaginator>(
	'SET_PAGINATION_META_DATA',
	paginationMetaData => paginationMetaData,
);

export const setRegisterRecordList = createAction<
	FormRegisterResource['registerRecord'],
	FormRegisterResource['registerRecord']
>(
	'SET_REGISTER_RECORD',
	registerRecordList => registerRecordList,
);

export const defaultUserForm = {
	name: { value: '', error: '' },
	nickname: { value: '', error: '' },
	mobile: { value: '', error: '' },
	email: { value: '', error: '' },
	identityNumber: { value: '', error: '' },
	birthday: { value: '', error: '' },
};

export interface State {
	loading: boolean;
	userInfo: UserResource;
	orderList: OrderResponseData;
	registerRecord: FormRegisterResource['registerRecord'];
	userForm: UserFormProperty;
	paginationMetaData?: GetListDataPaginator;
}

export const defaultState: State = {
	loading: false,
	userInfo: {},
	orderList: [],
	registerRecord: {},
	userForm: defaultUserForm,
	paginationMetaData: {},
};

export const reducer = {
	user: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_USER_PENDING: (state: State): State => ({
				...state,
				loading: true,
			}),

			GET_USER_FULFILLED: (state: State, action: Action<UserResource>): State => ({
				...state,
				loading: false,
				userInfo: action.payload,
			}),

			GET_ORDER_LIST_BY_USER_PENDING: (state: State): State => ({
				...state,
				loading: true,
			}),

			GET_ORDER_LIST_BY_USER_FULFILLED: (state: State, action: Action<OrderResponseData>): State => ({
				...state,
				loading: false,
				orderList: action.payload,
			}),

			UPDATE_USER_FORM: (state: State, action: Action<FormPayload>): State => ({
				...state,
				userForm: {
					...state.userForm,
					[action.payload.key]: {
						value: action.payload.value,
						error: action.payload.error,
					},
				},
			}),

			SET_PAGINATION_META_DATA: (state: State, action: Action<GetListDataPaginator>): State => ({
				...state,
				paginationMetaData: action.payload,
			}),

			SET_REGISTER_RECORD: (state: State, action: Action<FormRegisterResource['registerRecord']>): State => ({
				...state,
				registerRecord: action.payload,
			}),
		},
		defaultState,
	),
};

const selectUser = (state: GlobalState) => ({
	user: state.user.userInfo,
	userForm: state.user.userForm,
	orderList: state.user.orderList,
	registerRecord: state.user.registerRecord,
	paginationMetaData: state.user.paginationMetaData,
});

export const useUser = () => useRedux(selectUser, {
	getUser,
	getOrderListByUser,
	initUserForm,
	updateUserForm,
	submitUpdateUserForm,
	submitUpdateUserEmail
});
