import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	TravelColumnResource,
	GetListDataPaginator,
	V1TravelColumnsListRequestParams,
} from 'util/api/swaggerApi/data-contracts';

import { State as GlobalState } from './reducers';

/**
 * 取得旅遊專欄列表
 * @param tags 活動標籤
 * @param isFeatured 是否取得精選活動
 * @param page 頁碼
 */
export const getTravelBlog = createAction(
	'GET_TRAVEL_BLOG',
	(params: V1TravelColumnsListRequestParams) =>
		async (dispatch: Dispatch) => {
			const { tags, is_featured: isFeatured, page } = params;
			const { v1TravelColumnsList } = api;
			const { data, message } = await v1TravelColumnsList({
				tags,
				is_featured: isFeatured,
				page,
			});

			if (message !== 'success') {
				return [];
			}

			if (isFeatured) {
				dispatch(setFeaturedTravelBlog(data?.data || [] as TravelColumnResource[]));
				return null;
			}

			dispatch(setPaginationMetaData((data as { meta: GetListDataPaginator })?.meta));

			return data?.data || [] as TravelColumnResource[];
		}
);

/**
 * 取得精選專欄
 */
export const setFeaturedTravelBlog = createAction(
	'SET_FEATURED_TRAVEL_BLOG_LIST', 
	(featuredTravelBlog: TravelColumnResource[]) => featuredTravelBlog
);

/**
 * 取得旅遊專欄內頁資料
 */
export const getTravelBlogDataById = createAction<Promise<TravelColumnResource>, number>(
	'GET_TRAVEL_BLOG_DATA_BY_ID',
	async id => {
		const { v1TravelColumnDetail } = api;
		const { data, message } = await v1TravelColumnDetail(id);

		if (message !== 'success') {
			return {};
		}

		return data?.data as TravelColumnResource;
	},
);

export const setPaginationMetaData = createAction<GetListDataPaginator, GetListDataPaginator>(
	'SET_PAGINATION_META_DATA',
	paginationMetaData => paginationMetaData,
);

export interface State {
  loading: boolean;
  travelBlogList: TravelColumnResource[];
  featuredTravelBlogList: TravelColumnResource[];
	travelBlogData: TravelColumnResource,
	paginationMetaData?: GetListDataPaginator;
}

export const defaultState: State = {
	loading: false,
	travelBlogList: [],
	featuredTravelBlogList: [],
	travelBlogData: {},
	paginationMetaData: {},
};

export const reducer = {
	travelBlog: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_TRAVEL_BLOG_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TRAVEL_BLOG_FULFILLED: (state: State, action: Action<TravelColumnResource[]>): State => ({
				...state,
				loading: false,
				travelBlogList: action.payload,
			}),

			SET_PAGINATION_META_DATA: (state: State, action: Action<GetListDataPaginator>): State => ({
				...state,
				paginationMetaData: action.payload,
			}),

			SET_FEATURED_TRAVEL_BLOG_LIST: (state: State, action: Action<TravelColumnResource[]>): State => ({
				...state,
				featuredTravelBlogList: action.payload,
			}),
			
			GET_TRAVEL_BLOG_DATA_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TRAVEL_BLOG_DATA_BY_ID_FULFILLED: (state, action: Action<TravelColumnResource>) => ({
				...state,
				loading: false,
				travelBlogData: action.payload,
			}),
		},
		defaultState,
	),
};

const selectTravelBlog = (state: GlobalState) => ({
	travelBlogList: state.travelBlog.travelBlogList,
	featuredTravelBlogList: state.travelBlog.featuredTravelBlogList,
	travelBlogData: state.travelBlog.travelBlogData,
	paginationMetaData: state.travelBlog.paginationMetaData,
});

export const useTravelBlog = () => useRedux(selectTravelBlog, {
	getTravelBlog,
	getTravelBlogDataById,
});