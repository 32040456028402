import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getTagList } from 'models/tag';
import { getTravelBlog, getTravelBlogDataById } from 'models/travelBlog';

const routes: CustomRoute = {
	path: '/travel-blog',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([TravelBlog]) => <TravelBlog />,
			onEnter: async ({ store, next }) => {
				const children = await next();
				await store.dispatch(getTravelBlog({ is_featured: true }));
				await store.dispatch(getTravelBlog({ is_featured: false, page: 1 }));
				return children;
			},
		},
		{
			path: '/:id',
			components: () => [import('./TravelBlogDetail/component')],
			render: ([TravelBlogDetail]) => <TravelBlogDetail />,
			onEnter: async ({ store, params }) => {
				const id = parseInt(params.id as string, 10);
				if (!Number.isNaN(id)) {
					await store.dispatch(getTravelBlogDataById(id));
				}
				await store.dispatch(getTravelBlog({}));
			},
		},
		{
			path: '/tag/:tag',
			components: () => [import('./TravelBlogTagFiltered/component')],
			render: ([TravelBlogTagFiltered]) => <TravelBlogTagFiltered />,
			onEnter: async ({ store, params }) => {
				const { tag } = params;
				await store.dispatch(getTagList());
				await store.dispatch(getTravelBlog({ tags: tag as string, page: 1 }));
			},
		},
	],
};

export default routes;
