export const navigationLinks = [
	{ label: '華友行程', path: '/tours' },
	{ label: '出團總表', path: '/tour-list' },
	{ label: '華友活動', path: '/events' },
	{ label: '旅遊專欄', path: '/travel-blog' },
	{ label: '優惠方案', path: '/promotional-offers' },
];

export const memberMenuListLinks = [
	{ label: '我的行程', path: '/member/my-tour' },
	{ label: '活動講座', path: '/member/activities' },
	{ label: '聯絡訊息', path: '/member/contact' },
	{ label: '個人資料', path: '/member/profile' },
];
