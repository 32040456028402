import React from 'react';
import classnames from 'classnames';

import { useMedia } from 'util/hook/useMedia';
import { navigationLinks, memberMenuListLinks } from 'util/links';

import { useAuth } from 'models/auth';
import { useUser } from 'models/user';

import Link from 'components/atoms/Link';
import Icon from 'components/atoms/Icon';
import MenuTitle from 'components/atoms/MenuTitle';
import Search from 'components/molecules/Search';

import LineIcon from 'images/icon/line.inline.svg';
import MenuIcon from 'images/icon/menu-dark.inline.svg';
import LogoIcon from 'images/icon/logo.inline.svg';
import UserIcon from 'images/icon/user.inline.svg';
import CloseIcon from 'images/icon/close-circle.inline.svg';
import BackgroundImage from 'images/background/background-line-a.svg';

import styles from './index.module.css';

interface NavigationProperty {
	isMenuOpen: boolean;
	handleMenuOpen: (open: boolean) => void;
	className?: string;
	isMemberMenuOpen: boolean;
	memberMenuOpen: () => void;
	memberMenuClose: () => void;
	modalSignUpOpen: () => void;
	modalLoginOpen: () => void;
}

interface MenuButtonProps {
	isMenuOpen: boolean;
	handleMenuOpen: (open: boolean) => void;
	memberMenuClose: () => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({ isMenuOpen, handleMenuOpen, memberMenuClose }) => (
	<div
		role="button"
		onClick={() => {
			handleMenuOpen(true);
			memberMenuClose();
		}}
		onKeyDown={() => handleMenuOpen(true)}
		tabIndex={0}
		className={classnames(styles.hamburger, { [styles.menuOpen]: isMenuOpen })}
	>
		<MenuIcon />
	</div>
);

const Logo = () => (
	<Link className={styles.logo} to="/">
		<LogoIcon />
	</Link>
);

const Navigation: React.FC<NavigationProperty> = ({
	handleMenuOpen,
	isMenuOpen,
	className,
	isMemberMenuOpen,
	memberMenuOpen,
	memberMenuClose,
	modalSignUpOpen,
	modalLoginOpen,
}) => {
	const media = useMedia();
	const [{ isLogin }, { clearForm, logout }] = useAuth();
	const [{ user }] = useUser();

	if (media !== 'desktop') {
		return (
			<nav className={classnames(styles.navigation)}>
				<div className={styles.hamburgerWrapper}>
					<MenuButton
						isMenuOpen={isMenuOpen}
						handleMenuOpen={handleMenuOpen}
						memberMenuClose={memberMenuClose}
					/>
					{isLogin && (
						<Icon
							className={styles.userIcon}
							src={UserIcon}
							onClick={() => {
								memberMenuOpen();
								handleMenuOpen(false);
							}}
						/>
					)}
				</div>
				<div className={styles.buttonGroup}>
					<a
						className={styles.link}
						href="https://lin.ee/wkcAObL"
						rel="noreferrer"
						role="button"
						target="_blank"
					>
						<LineIcon />
					</a>
					<Search />
				</div>
				<Logo />
				{isMemberMenuOpen && (
					<div className={styles.memberMenuWrapper}>
						<div
							className={styles.close}
							role="button"
							onClick={memberMenuClose}
							onKeyDown={memberMenuClose}
							tabIndex={0}
						>
							<CloseIcon />
						</div>
						<div className={styles.menu}>
							<div
								className={styles.background}
								style={{ backgroundImage: `url(${BackgroundImage})` }}
							/>
							<div className={styles.contentWrapper}>
								<MenuTitle className={styles.menuTitle} title="MEMBER" />
								<div className={styles.userIcon}>
									<Icon className={styles.icon} src={UserIcon} size="large" />
									{user.nickname ? user.nickname : user.name}
								</div>
								<ul className={styles.menuList}>
									{memberMenuListLinks.map(link => (
										<li key={link.label}>
											<Link className={styles.link} onClick={memberMenuClose} to={link.path}>
												{link.label}
											</Link>
										</li>
									))}
									<li>
										<button
											className={classnames(styles.link, styles.button)}
											type="button"
											onClick={logout}
										>
											登出
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				)}
			</nav>
		);
	}

	return (
		<div className={classnames(styles.navigation, className)}>
			<ul className={styles.linkList}>
				{navigationLinks.map(link => (
					<li key={link.label}>
						<Link className={styles.link} to={link.path}>
							{link.label}
						</Link>
					</li>
				))}
			</ul>

			<div className={styles.buttonGroup}>
				<Link type="external" to="https://lin.ee/wkcAObL">
					<LineIcon />
				</Link>
				<Search />
				{isLogin ? (
					<div className={styles.userButton}>
						<Icon className={styles.userIcon} src={UserIcon} size="large" />
						<div className={styles.userMenuWrapper}>
							<div className={styles.userMenuHeader}>
								<Icon className={styles.userIcon} src={UserIcon} size="large" />
								{user.nickname ? user.nickname : user.name}
							</div>
							<ul className={styles.userMenuContent}>
								{memberMenuListLinks.map(link => (
									<li className={styles.innerItem} key={link.label}>
										<Link to={link.path}>{link.label}</Link>
									</li>
								))}
							</ul>
							<div className={styles.userMenuFooter}>
								<button type="button" onClick={logout}>
									登出
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className={styles.memberButton}>
						<button
							type="button"
							className={styles.link}
							onClick={() => {
								clearForm('signUpForm');
								modalSignUpOpen();
							}}
						>
							註冊
						</button>
						<span>|</span>
						<button
							type="button"
							className={styles.link}
							onClick={() => {
								clearForm('loginForm');
								modalLoginOpen();
							}}
						>
							登入
						</button>
					</div>
				)}
			</div>

			<Logo />
		</div>
	);
};

export default Navigation;
