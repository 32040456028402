import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { initUserForm } from 'models/user';

const routes: CustomRoute = {
	path: '/member',
	components: () => [import(/* webpackChunkName: 'Member' */ './component')],
	render: (_, children) => children,
	onEnter: async ({ store, next, history: { replace } }) => {
		const children = await next();
		const {
			auth: { token },
		} = store.getState();
		
		if (!token) {
			replace('/');
		}

		return children;
	},
	children: [
		{
			path: '/my-tour',
			components: () => [import('./MyTour/component')],
			render: ([MyTour]) => <MyTour />,
		},
		{
			path: '/activities',
			components: () => [import('./Activities/component')],
			render: ([Activities]) => <Activities />,
		},
		{
			path: '/contact',
			components: () => [import('./Contact/component')],
			render: ([Contact]) => <Contact />,
		},
		{
			path: '/profile',
			components: () => [import('./Profile/component')],
			render: ([Profile]) => <Profile />,
			onEnter: async ({ store, next }) => {
				const children = await next();
				store.dispatch(initUserForm());
				return children;
			},
		},
	],
};

export default routes;
