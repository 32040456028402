import { createAction, handleActions, Action } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';

import { TagShowResource, TagTourStyleCollection } from 'util/api/swaggerApi/data-contracts';
import { State as GlobalState } from './reducers';

export const getTagList = createAction<Promise<TagShowResource[]>>('GET_TAG_LIST', async () => {
	const { v1TagList } = api;
	const { data, status } = await v1TagList();

	if (status !== 200 && status !== 201) {
		return [];
	}

	return data?.data as TagShowResource[];
});

export const getTourThemeTagList = createAction<Promise<TagTourStyleCollection[]>>('GET_TOUR_THEME_TAG_LIST', async () => {
	const { v1TagTourStyleList } = api;
	const { data, status } = await v1TagTourStyleList();

	if (status !== 200 && status !== 201) {
		return [];
	}

	return data?.data as TagTourStyleCollection[];
});

export interface State {
	loading: boolean;
	tagList: TagShowResource[];
	tourThemeTagList: TagTourStyleCollection[];
}

export const defaultState: State = {
	loading: false,
	tagList: [],
	tourThemeTagList: [],
};

export const reducer = {
	tags: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_TAG_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TAG_LIST_FULFILLED: (state, action: Action<TagShowResource[]>) => ({
				...state,
				tagList: action.payload,
				loading: false,
			}),

			GET_TOUR_THEME_TAG_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TOUR_THEME_TAG_LIST_FULFILLED: (state, action: Action<TagTourStyleCollection[]>) => ({
				...state,
				tourThemeTagList: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	tagList: state.tags.tagList,
	tourThemeTagList: state.tags.tourThemeTagList,
});

export const useTags = () => useRedux(mapHooksToState, { getTagList, getTourThemeTagList });
