import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getToursList, clearTourFilter, setTourFilter, TourFilterField } from 'models/tour';
import { isExist } from 'util/helper';

import TourRoute from './Tour';

const routes: CustomRoute = {
	path: '/tours',
	components: () => [import('./component')],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		console.log('on Enter tours');
		const children = await next();
		console.log('on Enter tours / end');

		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Tours]) => <Tours />,
			onEnter: async ({ next, store, query }) => {
				const { countries, tags, dateStart, dateEnd } = query;
				store.dispatch(clearTourFilter());

				const filters = [
					{ field: 'countries', value: countries },
					{ field: 'tags', value: tags },
					{ field: 'dateStart', value: dateStart },
					{ field: 'dateEnd', value: dateEnd },
				];

				filters.forEach(({ field, value }) => {
					if (isExist(value)) {
						store.dispatch(setTourFilter({ field: field as TourFilterField, value }));
					}
				});

				await store.dispatch(getToursList(1));

				const children = await next();
				return children;
			},
		},
		TourRoute,
	],
};

export default routes;
