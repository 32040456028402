import React, { useRef, useCallback, useEffect } from 'react';
import classnames from 'classnames';

import useOnClickOutside from 'util/hook/useOnClickOutside';
import useEventListener from 'util/hook/useEventListener';
import { fixBackgroundScrollY, unfixBackgroundScrollY } from 'util/hook/useLockScroll';

import Portal from 'components/atoms/Portal';
import Icon from 'components/atoms/Icon';
import Divider from 'components/atoms/Divider';

import CloseIcon from 'images/icon/close.inline.svg';
import ArrowIcon from 'images/icon/arrow-left-black.inline.svg';

import styles from './index.module.css';

export interface ModalProps {
	className?: string;
	coverClassName?: string;
	modalContainerClassName?: string;
	withCloseButton?: boolean;
	canClickOutsideClose?: boolean;
	returnButton?: boolean;
	isOpen: boolean;
	isNestedModal?: boolean;
	closeModal: () => void;
	type?: string;
	title?: string;
	contentBodyClassName?: string;
	footerComponent?: React.ReactNode;
	footerClassName?: string;
	children: React.ReactNode;
	outerChildren?: React.ReactNode | (() => React.ReactNode) | undefined;
}

const Modal: React.FC<ModalProps> = ({
	className,
	coverClassName,
	modalContainerClassName,
	withCloseButton = true,
	canClickOutsideClose = true,
	isOpen = false,
	isNestedModal = false,
	returnButton = false,
	closeModal = () => {},
	type = '',
	title = '',
	contentBodyClassName,
	footerComponent,
	footerClassName,
	children,
	outerChildren,
}) => {
	const refModal = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!isNestedModal) {
			if (isOpen) {
				fixBackgroundScrollY(); // 鎖定背景滾動
			} else {
				unfixBackgroundScrollY();
			}
		}
	}, [isOpen]);

	const handleEscape = useCallback(
		(event: Event) => {
			if (event.defaultPrevented) {
				return;
			}

			const key = (event as KeyboardEvent).key || (event as KeyboardEvent).code;
			if (key === 'Escape' || key === 'Esc') {
				closeModal();
			}
		},
		[closeModal],
	);

	useEventListener('keydown', withCloseButton ? handleEscape : () => {});
	useOnClickOutside(refModal, canClickOutsideClose ? closeModal : () => {});

	return (
		<Portal>
			<div
				className={classnames(styles.modalCover, coverClassName, {
					[styles.modalWhiteCover]: type === 'white',
					[styles.active]: isOpen,
					[styles.isNestedModal]: isNestedModal,
				})}
				ref={refModal}
			>
				<div className={classnames(styles.modalContainer, modalContainerClassName)}>
					<div className={classnames(styles.modal, className)}>
						{returnButton ? (
							<div className={styles.returnButtonWrapper}>
								<Icon className={styles.close} src={ArrowIcon} />
								<p className={styles.header}>{title}</p>
							</div>
						) : (
							<p className={styles.header}>{title}</p>
						)}
						<Divider />
						<div className={classnames(styles.body, contentBodyClassName)}>{children}</div>
						{footerComponent !== undefined && (
							<>
								<Divider type="bottom" />
								<div className={classnames(styles.footer, footerClassName)}>{footerComponent}</div>
							</>
						)}
						{withCloseButton && (
							<Icon className={styles.close} src={CloseIcon} onClick={closeModal} />
						)}
					</div>
					{outerChildren && (
						<div className={styles.outerSection}>
							{React.isValidElement(outerChildren)
								? outerChildren
								: (outerChildren as () => React.ReactNode)()}
						</div>
					)}
				</div>
			</div>
		</Portal>
	);
};

export default Modal;
