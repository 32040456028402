import React from 'react';
import classnames from 'classnames';

import { useMedia } from 'util/hook/useMedia';

import Link from 'components/atoms/Link';

import CircleDashSmallIcon from 'images/icon/circle-dash-small.inline.svg';
import MobileCircleDashSmallIcon from 'images/icon/circle-dash-small-mobile.inline.svg';
import CircleDashLargeIcon from 'images/icon/circle-dash-large.inline.svg';
import MobileCircleDashLargeIcon from 'images/icon/circle-dash-large-mobile.inline.svg';
import SearchIcon from 'images/icon/search.inline.svg';

import styles from './index.module.css';

interface CircleButtonContentProperty {
	className?: string;
	title?: string;
	subtitle?: string;
	hasBackground?: boolean;
	searchIconOnly?: boolean;
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface CircleButtonProperty extends CircleButtonContentProperty {
	hasLink?: boolean;
	LinkType?: 'internal' | 'external';
	LinkTo?: string;
}

const CircleButtonContent: React.FC<CircleButtonContentProperty> = ({
	className,
	title,
	subtitle,
	hasBackground = false,
	searchIconOnly = false,
	onClick,
}) => {
	const media = useMedia();

	return (
		<div
			className={classnames(styles.circleButton, className, {
				[styles.background]: hasBackground,
				[styles.iconOnly]: searchIconOnly,
			})}
			role="button"
			tabIndex={0}
			onClick={onClick}
			onKeyPress={() => {}}
		>
			{searchIconOnly ? (
				<>
					{media === 'mobile' ? <MobileCircleDashSmallIcon /> : <CircleDashSmallIcon />}
					<div className={styles.content}>
						<SearchIcon />
					</div>
				</>
			) : (
				<>
					{media === 'mobile' ? <MobileCircleDashLargeIcon /> : <CircleDashLargeIcon />}
					<div className={styles.content}>
						<p>{title}</p>
						<p>{subtitle}</p>
					</div>
				</>
			)}
		</div>
	);
};

const CircleButton: React.FC<CircleButtonProperty> = ({
	className,
	title,
	subtitle,
	hasBackground = false,
	searchIconOnly = false,
	hasLink = false,
	LinkType = 'internal',
	LinkTo = '',
	onClick = () => {},
}) => {
	if (hasLink) {
		return (
			<Link className={classnames(styles.link, className)} type={LinkType} to={LinkTo}>
				<CircleButtonContent
					title={title}
					subtitle={subtitle}
					hasBackground={hasBackground}
					searchIconOnly={searchIconOnly}
					onClick={onClick}
				/>
			</Link>
		);
	}

	return (
		<CircleButtonContent
			className={className}
			title={title}
			subtitle={subtitle}
			hasBackground={hasBackground}
			searchIconOnly={searchIconOnly}
			onClick={onClick}
		/>
	);
};

export default CircleButton;
