import { createAction, handleActions, Action } from 'redux-actions';

import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';

import { HomePageCollection, PraiseResource } from 'util/api/swaggerApi/data-contracts';
import { State as GlobalState } from './reducers';

export const getHomePageList = createAction<Promise<HomePageCollection['data']>>('GET_HOME_PAGE_LIST', async () => {
	const { v1HomePageList } = api;
	const { data, status } = await v1HomePageList();

	if (status !== 200 && status !== 201) {
		return [];
	}

	return data?.data;
});

export const getPraiseList = createAction<Promise<PraiseResource[]>>('GET_PRAISE_LIST', async () => {
	const { v1PraiseList } = api;
	const { data, status } = await v1PraiseList();

	if (status !== 200 && status !== 201) {
		return [];
	}

	return data?.data as PraiseResource[];
});

export interface State {
	loading: boolean;
	homePageList: HomePageCollection['data'];
	praiseList: PraiseResource[];
}

export const defaultState: State = {
	loading: false,
	homePageList: [],
	praiseList: [],
};

export const reducer = {
	home: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_HOME_PAGE_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_HOME_PAGE_LIST_FULFILLED: (state, action: Action<HomePageCollection['data']>) => ({
				...state,
				homePageList: action.payload,
				loading: false,
			}),

			GET_PRAISE_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_PRAISE_LIST_FULFILLED: (state, action: Action<PraiseResource[]>) => ({
				...state,
				praiseList: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	homePageList: state.home.homePageList,
});

export const useHomePage = () => useRedux(mapHooksToState, { getHomePageList });

const selectPraise = (state: GlobalState) => ({
	praiseList: state.home.praiseList,
});

export const usePraise = () => useRedux(selectPraise, { getPraiseList });
