/* eslint-disable no-shadow */
import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { State as GlobalState } from './reducers';

export enum MODAL_CATEGORY {
	TOAST = 'toast',
	MODAL = 'modal',
};

export enum TOAST_TYPE {
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error',
	NULL = '',
};

export enum MODAL_TYPE {
	WARMING = 'warning',
	NULL = '',
}

interface ModalPayload {
	category: MODAL_CATEGORY;
	type: TOAST_TYPE | MODAL_TYPE;
	data: string;
}

export const openModal = createAction<ModalPayload, ModalPayload>(
	'OPEN_MODAL',
	data => ({
		...data
	}),
);

export const closeModal = createAction('CLOSE_MODAL', (category: MODAL_CATEGORY) => category);

export type State = {
	toast: {
		type: TOAST_TYPE;
		data?: string;
	};
	modal: {
		type: MODAL_TYPE;
		data?: unknown;
	};
};

export const defaultState: State = {
	toast: { type: TOAST_TYPE.NULL, data: '' },
	modal: { type: MODAL_TYPE.NULL, data: {} },
};

export const reducer = {
	modal: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			OPEN_MODAL: (state, action: Action<ModalPayload>) => ({
				...state,
				[action.payload.category]: {
					...state[action.payload.category],
					type: action.payload.type,
					...(action.payload.data && { data: action.payload.data }),
				},
			}),

			CLOSE_MODAL: (state, action: Action<MODAL_CATEGORY>) => ({
				...state,
				[action.payload]: {
					...defaultState[action.payload],
				},
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	modal: state.modal,
});

export const useModal = () => useRedux(mapHooksToState, { openModal, closeModal });
