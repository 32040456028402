import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getTagList } from 'models/tag';
import { getPromotionalOffers, getPromotionalOffersDataById } from 'models/promotionalOffers';

const routes: CustomRoute = {
	path: '/promotional-offers',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([PromotionalOffers]) => <PromotionalOffers />,
			onEnter: async ({ store, next }) => {
				const children = await next();
				await store.dispatch(getPromotionalOffers({ is_featured: true }));
				await store.dispatch(getPromotionalOffers({ is_featured: false, page: 1 }));
				return children;
			},
		},
		{
			path: '/:id',
			components: () => [import('./PromotionalOffersDetail/component')],
			render: ([PromotionalOffersDetail]) => <PromotionalOffersDetail />,
			onEnter: async ({ store, params }) => {
				const id = parseInt(params.id as string, 10);
				if (!Number.isNaN(id)) {
					await store.dispatch(getPromotionalOffersDataById(id));
				}
				await store.dispatch(getPromotionalOffers({}));
			},
		},
		{
			path: '/tag/:tag',
			components: () => [import('./PromotionalOffersTagFiltered/component')],
			render: ([PromotionalOffersTagFiltered]) => <PromotionalOffersTagFiltered />,
			onEnter: async ({ store, params }) => {
				const { tag } = params;
				await store.dispatch(getTagList());
				await store.dispatch(getPromotionalOffers({ tags: tag as string, page: 1 }));
			},
		},
	],
};

export default routes;
