import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getTourDataById, getTravelTipById, getTourDepartureDataById } from 'models/tour';

const routes: CustomRoute = {
	path: '/trip-handbook/:tourId',
	components: () => [import('./component')],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Handbook]) => <Handbook isTourType />,
			onEnter: async ({ params, store }) => {
				const tourId = parseInt(params.tourId as string, 10);
				if (!Number.isNaN(tourId)) {
					await store.dispatch(getTourDataById(tourId));
					await store.dispatch(getTravelTipById(tourId));
				}
			},
		},
		{
			path: '/:departureId',
			components: () => [import('./component')],
			render: ([Handbook]) => <Handbook isDepartureType />,
			onEnter: async ({ params, store }) => {
				console.log('華友行程 - 出團內頁', params.tourId, params.departureId);

				const departureId = parseInt(params.departureId as string, 10);
				if (!Number.isNaN(departureId)) {
					await store.dispatch(getTourDepartureDataById(departureId));
				}
				await store.dispatch(getTravelTipById(parseInt(params.tourId as string, 10)));
			},
		},
	],
};

export default routes;
