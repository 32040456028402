import React from 'react';
import classnames from 'classnames';

import Button from 'components/atoms/Button';
import Modal from 'components/atoms/Modal';

import styles from './index.module.css';

interface ModalPrivacyProperty {
	className?: string;
	isOpen: boolean;
	closeModal: () => void;
}

const ModalFooterContent: React.FC<{ closeModal: () => void }> = ({ closeModal }) => (
	<div className={styles.buttonWrapper}>
		<Button className={styles.button} size="large" fullWidth onClick={closeModal}>
			確認
		</Button>
	</div>
);

const ModalPrivacy: React.FC<ModalPrivacyProperty> = ({
	className,
	isOpen = false,
	closeModal,
}) => (
	<Modal
		className={classnames(styles.modalPrivacy, className)}
		modalContainerClassName={styles.modalContainer}
		isOpen={isOpen}
		// isNestedModal
		closeModal={closeModal}
		title="隱私權保護政策與使用約定條款"
		outerChildren={<ModalFooterContent closeModal={closeModal} /> as React.ReactNode}
	>
		<div className={styles.modalInnerWrapper}>
			<ol>
				<li className={styles.sort}>
					<h3 className={styles.title}>隱私權保護政策適用範圍</h3>
					<p>
						隱私權保護政策適用於本網站之平台與網域，所蒐集到的客戶(即使用者)之相關資訊，包括華友旅行社有限公司(以下稱「華友旅行」)如何處理使用者於本網站中之作為，包括但不限於身分識別資料與其他相關數位資訊。
					</p>
					<p>本政策不適用於本網站以外之相關連結與非華友旅行委託或參與管理之人員。</p>
				</li>
				<li className={styles.sort}>
					<h3 className={styles.title}>個人資料蒐集資訊處理單位</h3>
					<p>
						華友旅行有限公司 <br />
						官方網站網址：
						<a href="https://www.mitravel.com.tw/" target="_blank" rel="noopener noreferrer">
							https://www.mitravel.com.tw/
						</a>
					</p>
				</li>
				<li className={styles.sort}>個人資料蒐集、處理與利用</li>
				<ol className={styles.bullet}>
					<li>客戶於華友旅行官方網站中進行登錄、申請、查詢中之必須填寫事項。</li>
					<li>
						透過其他方式，例如電子郵件、實體信件、書面、電話或口頭等方式藉由客戶與華友旅行之間往來之電子郵件、信件、書面文件電話或口頭等方式所提出之個人資訊而取得。
					</li>
					<li>
						由客戶連線到網站時所取得資訊：當使用者使用本網站服務時，華友旅行可能會就使用者電腦設定存取
						Cookie，Cookie 資訊可能包含使用者喜好、興趣、瀏覽記錄與進入網站前的URL、瀏覽器種類與 IP
						位置；如使用者不同意提供 cookie
						資訊，可自行將瀏覽器cookie設定關閉，但關閉後可能會有部分網站功能或服務無法使用。
					</li>
					<li>
						華友旅行僅會使用客戶所提供之個人資訊，作為告知客戶之特定目的使用，如：機票之開立、保險、行程中的飯店、餐食、門票…等安排，其中轉至第第三方之相關資訊，可見本條款第四項。
					</li>
				</ol>
				<li className={styles.sort}>
					<h3 className={styles.title}>對於第三方平台之資訊蒐集與提供</h3>
					<p>
						華友旅行不會主動將使用者相關資訊提供給第三方使用，但下述情況，華友旅行得將客戶資料提供至以下單位：
					</p>
				</li>
				<ol className={styles.bullet}>
					<li>
						客戶透過華友旅行架設於第三方平台之聯繫管道 (如META旗下軟體與平台、LINE官方帳號)
						所提供之相關資訊。華友旅行將透過平台取得你所提供之資訊，平台方也有權蒐集相關資訊。
					</li>
					<li>
						對於信用卡公司與其他金融、金流單位，於客戶付款時，華友旅行因請款之必需得向信用卡公司或其他金融機構提供付款所需資訊。
					</li>
					<li>基於法律規定，防止他人權益危害，或受行政、司法單位之要求交付。</li>
				</ol>
				<li className={styles.sort}>
					<h3 className={styles.title}>兒童及青少年網路安全之保護</h3>
					<p>
						華友旅行為對兒童與青年之網路行為進行保護。針對兒童及及未滿十八歲之青少年，其家長（或監護人）應盡到下列義務：未滿十二歲之兒童使用本服務時時，應全程在旁陪伴，十二歲以上未滿十八歲之青少年使用本服務前亦應與他們討論並取得同意。十八歲以下之本服務使用者將個人資訊提供給予華友旅行，須有家長（或監護人）的同意。若要詢問或刪除十八歲以下之本網站使用者所登錄之個人資訊時，請與個人資訊諮詢窗口：
						<a href="mailto:info@mitravel.com.tw">info@mitravel.com.tw</a> 聯繫。
					</p>
				</li>
				<li className={styles.sort}>
					<h3 className={styles.title}>隱私權保護政策之修正</h3>
					<p>
						為遵守台灣相關法令進行個人資訊之管理與使用，華友旅行得隨時修訂隱私權聲明，並將修正後條款刊登於華友旅行官方網站。
					</p>
				</li>
				<li className={styles.sort}>
					<h3 className={styles.title}>個人資料保護諮詢窗口</h3>
					<p>
						若使用者於華友旅行網站上的隱私權有任何問題，或有所疑慮、或欲請求閱覽、複本、補充或更正、停止蒐集、處理、利用或刪除其個人資料，請利用華友旅行服務信箱{' '}
						<a href="mailto:info@mitravel.com.tw">info@mitravel.com.tw</a>{' '}
						與我們聯繫，我們將盡最大努力儘快改善您提出的建議，以提供更優質的服務。
					</p>
				</li>
			</ol>
		</div>
	</Modal>
);

export default ModalPrivacy;
