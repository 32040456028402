import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getTourDataById, getTourDepartureDataById, getTourDeparturesList } from 'models/tour';
import { getAllCountries } from 'models/country';

const routes: CustomRoute = {
	path: '/:tourId',
	components: () => [import('./component')],
	render: (_, children) => children,
	onEnter: async ({ next, params, store }) => {
		console.log('on Enter tour');

		const tourId = parseInt(params.tourId as string, 10);
		if (!Number.isNaN(tourId)) {
			await store.dispatch(getTourDataById(tourId));
		}
		const children = await next();

		console.log('on Enter tour / end');
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Tour]) => <Tour isTourType />,
			onEnter: async ({ params, store }) => {
				console.log('華友行程 - 列表頁', params.tourId);
				await store.dispatch(getTourDeparturesList(1, parseInt(params.tourId as string, 10), 5));
				await store.dispatch(getAllCountries());
			},
		},
		{
			path: '/:departureId',
			components: () => [import('./component')],
			render: ([Tour]) => <Tour isDepartureType />,
			onEnter: async ({ params, store }) => {
				console.log('華友行程 - 出團內頁', params.tourId, params.departureId);

				const departureId = parseInt(params.departureId as string, 10);
				if (!Number.isNaN(departureId)) {
					await store.dispatch(getTourDepartureDataById(departureId));
				}
				await store.dispatch(getTourDeparturesList(1, parseInt(params.tourId as string, 10)));
				await store.dispatch(getAllCountries());
			},
		},
	],
};

export default routes;
