/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, FocusEvent, useState } from 'react';
import classnames from 'classnames';

import Icon from 'components/atoms/Icon';
import ReminderPrompt from 'components/atoms/ReminderPrompt';

import VisibleIcon from 'images/icon/password-visible.inline.svg';
import InvisibleIcon from 'images/icon/password-invisible.inline.svg';
import ErrorIcon from 'images/icon/toast-error.inline.svg';

import styles from './index.module.css';

interface InputProperty {
	className?: string;
	type?: 'text' | 'number' | 'password' | 'textarea';
	isPassword?: boolean;
	hasPasswordReminder?: boolean;
	labelText?: string;
	placeholder: string;
	value?: string;
	error?: string;
	maxLength?: number;
	required?: boolean;
	disabled?: boolean;
	onChange?: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProperty> = ({
	className,
	type = 'text',
	isPassword = false,
	hasPasswordReminder = false,
	labelText = '',
	placeholder = '',
	value = '',
	error = '',
	maxLength,
	required = false,
	disabled = false,
	onChange = () => {},
	onBlur = () => {},
	onFocus = () => {},
}) => {
	const [visible, setVisible] = useState(false);

	const handleOnInput = (e: React.FormEvent<HTMLInputElement>) => {
		// 讓 input value 長度不超過 maxLength。
		if (maxLength) {
			e.currentTarget.value = e.currentTarget.value.slice(0, maxLength);
		}
	};

	const handleInputType = () => {
		if (isPassword) {
			return visible ? 'text' : 'password';
		}
		return type;
	};

	const renderInput = () => (
		<input
			className={classnames(styles.input, {
				[styles.inputPassword]: isPassword,
				[styles.error]: error,
			})}
			type={handleInputType()}
			placeholder={placeholder}
			value={value}
			maxLength={maxLength}
			required={required}
			disabled={disabled}
			onInput={handleOnInput}
			onChange={onChange}
			onBlur={onBlur}
			onFocus={onFocus}
			autoComplete="new-password"
		/>
	);

	return (
		<div className={classnames(className, styles.inputWrapper)}>
			<label className={styles.label}>
				{labelText && (
					<span className={styles.labelText}>
						{labelText}
						{required && <sup className={styles.requireHint}>*</sup>}
					</span>
				)}
				{type === 'textarea' && (
					<textarea
						className={styles.textarea}
						placeholder={placeholder}
						value={value}
						maxLength={maxLength}
						disabled={disabled}
						onChange={onChange}
					/>
				)}
				{(type === 'text' || type === 'number') && renderInput()}
				{isPassword && (
					<>
						<div className={classnames(styles.inputPasswordWrapper, { [styles.error]: error })}>
							{renderInput()}
							<Icon
								className={styles.iconPassword}
								src={visible ? VisibleIcon : InvisibleIcon}
								size="small"
								onClick={() => setVisible(isHide => !isHide)}
							/>
						</div>
						{hasPasswordReminder && <ReminderPrompt text="請設定 8~20 位數的英數字混合密碼" />}
					</>
				)}
			</label>
			{error && (
				<div className={styles.errorMessage}>
					<Icon src={ErrorIcon} className={styles.icon} size="small" />
					{error}
				</div>
			)}
		</div>
	);
};

export default Input;
