import React from 'react';
import classNames from 'classnames';

import styles from './index.module.css';

interface DividerProperty {
	type?: 'top' | 'bottom';
	className?: string;
}

const Divider: React.FC<DividerProperty> = ({ type = 'top', className }) => (
	<div className={classNames(styles.divider, styles[type], className)} />
);

export default Divider;
