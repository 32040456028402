import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';
import { api, authApi } from 'util/api';
import {
	V1FormSubmitContactCreateRequestPayload,
	V1FormSubmitRegisterCreateRequestPayload,
	V1FormSubmitEventCreateRequestPayload 
} from 'util/api/swaggerApi/data-contracts';
import { handlePurposeValue } from 'util/helper';

import { signUp, updateAuthToken } from 'models/auth';
import { submitUpdateUserEmail } from 'models/user';

import { GetState, State as GlobalState } from './reducers';

export const updateVerifyCodeForm = createAction(
	'UPDATE_VERIFY_CODE_FORM',
	({ value, token, error }: VerifyCodeFormItemPayload) => ({
		value,
		token,
		error,
	}),
);

export const updateForm = createAction(
	'UPDATE_FORM',
	({ type, key, data, error }: FormPayload) => ({
		type,
		key,
		data,
		error,
	}),
);

export const updateContactInquiryForm = createAction(
	'UPDATE_CONTACT_INQUIRY_FORM',
	({ key, data, error }: FormPayload) => ({
		key,
		data,
		error,
	}),
);

export const updateSentEmail = createAction(
	'UPDATE_SENT_EMAIL',
	(value : string) => value);

export const clearForm = createAction('CLEAR_FORM', (type: string) => {
	let clearData;

	switch (type) {
		case 'contactInquiryForm':
			clearData = defaultContactInquiryForm;
			break;
		case 'tourRegisterForm':
			clearData = defaultTourRegisterForm;
			break;
		case 'eventRegisterForm':
			clearData = defaultEventRegisterForm;
			break;
		case 'verifyCode':
			clearData = defaultVerifyCode;
			break;
		case 'sentEmail':
			clearData = '';
			break;
		default:
			break;
	}

	return {
		type,
		data: clearData,
	};
});

/**
 * 寄送驗證碼
**/
export const sendVerifyCode = createAction(
	'SEND_VERIFY_CODE',
	({
	  email,
	  verifyType,
	  progressStepChange,
		step: progressStep,
	  currentStep,
		setStep,
	}: {
    email: string;
    verifyType:
      | 'form-submit-register' // 表單 - 行程報名
      | 'form-submit-event' // 表單 - 活動報名
      | 'member-register' // 會員 - 註冊
      | 'member-resetPassword' // 會員 - 重設密碼
      | 'member-updateUserEmail'; // 會員 - 編輯信箱
		progressStepChange?: (step: 'next' | 'back') => void;
		step?: 'next' | 'back';
    currentStep?: string;
		setStep?: React.Dispatch<React.SetStateAction<string>>;
  }) => async () => {
		const stepActions = {
		  next: 'next',
		  back: 'back',
		};

		try {
	  const { v1SendVerifyCodeCreate } = api;
	  const { status } = await v1SendVerifyCodeCreate({
	    email,
	    verify_type: verifyType,
	  });
			if (status !== 200) return null;
			
			if (
				verifyType === 'form-submit-register' &&
				progressStepChange &&
				progressStep &&
				stepActions[progressStep]
			) {
				progressStepChange(stepActions[progressStep] as 'next' | 'back');
			}

			if (verifyType === 'form-submit-event') {
				if (setStep) {
					setStep('verifyCode');
				}
			}

			return null;
		} catch (e) {
			const errorMessage = (e as { error?: { message?: string } }).error?.message;

			if (errorMessage === '重複寄送驗證碼') {
		  if (
		    currentStep === 'userInfo' ||
		    currentStep === 'TourRegisterFormUserInfo'
		  ) {
					if (progressStepChange && progressStep) { // 避免 progressStepChange 和 progressStep 為 undefined
						progressStepChange(stepActions[progressStep] as 'next' | 'back');
					}
		  }

				return null;
			}

			return null;
		}
	},
);

/**
 * 送出驗證碼（驗證驗證碼）
**/
export const submitVerifyCode = createAction(
	'SUBMIT_VERIFY_CODE',
	({
		email,
		verifyType,
	  progressStepChange,
		setStep,
		closeModal,
	} : {
		email: string;
    verifyType:
      | 'form-submit-register' // 表單 - 行程報名
      | 'form-submit-event' // 表單 - 活動報名
      | 'member-register' // 會員 - 註冊
      | 'member-resetPassword' // 會員 - 重設密碼
      | 'member-updateUserEmail'; // 會員 - 編輯信箱
		progressStepChange: () => void;
		setStep: (step: string) => void;
		closeModal?: () => void;
	}) =>
		async (dispatch: Dispatch, getState: GetState) => {
			const {
				form: { verifyCode },
			} = getState();

			try {
				const { v1VerifyCodeCreate } = api;
				const { status, data } = await v1VerifyCodeCreate({
					email,
					verify_type: verifyType,
					verify_code: verifyCode.value
				});

				if (status === 200) {
					switch (verifyType) {
						case 'form-submit-register':
							dispatch(submitTourRegisterForm({ progressStepChange, token: data?.data }))
							break;
						case 'form-submit-event':
							dispatch(submitEventRegisterForm({ setStep, token: data?.data }))
							break;
						case 'member-register':
							dispatch(signUp({ setStep, verifyCodeToken: data?.data }));
							dispatch(updateAuthToken({ type: 'verifyCode', token: data?.data }));
							break;
						case 'member-resetPassword':
							setStep('resetPassword');
							dispatch(updateAuthToken({ type: 'verifyCode', token: data?.data }));
							break;
						case 'member-updateUserEmail':
							dispatch(updateAuthToken({ type: 'verifyCode', token: data?.data }));
							if (closeModal) {
								dispatch(submitUpdateUserEmail({ closeModal }));
							};
							break;
							
						default:
							break;
					}
				}

				return null;
			} catch (e) {
				const errorMessage = (e as { error: { message: string } }).error?.message;
				let updatedError = '發生錯誤';

				if (errorMessage === '驗證碼錯誤') {
					updatedError = '驗證碼錯誤';
				}

				if (errorMessage === '驗證碼已失效') {
					updatedError = '驗證碼已失效';
				}

				return dispatch(
					updateVerifyCodeForm({
						value: verifyCode.value,
						error: updatedError,
					}),
				);
			}
		},
);

/**
 * 送出聯絡我們 / 行程諮詢表單
**/
export const submitContactInquiryForm = createAction(
	'SUBMIT_CONTACT_INQUIRY_FORM',
	(progressStepChange: (step: 'next' | 'back') => void) =>
		async (_: Dispatch, getState: GetState) => {
			const {
				form: { contactInquiryForm },
			} = getState();

			const {
				name,
				mobile,
				email,
				location,
				headcount,
				purpose,
				otherPurpose,
				suggestion,
				userId,
				tourGroupNumber,
				tourName,
			} = contactInquiryForm;

			const headcountIncludeNumber = headcount.value.match(/\d+/);
			
			const formData = {
				name: name.value,
				mobile: mobile.value,
				email: email.value,
				location:
					Array.isArray(location.value) ? location.value.map(item => item.value).join('、') : location.value,
				headcount: headcountIncludeNumber ? parseInt(headcountIncludeNumber[0], 10): headcount.value,
				purpose: handlePurposeValue(purpose.value, otherPurpose),
				suggestion: suggestion.value,
				...(userId?.value && { user_id: userId.value }),
				...(tourGroupNumber?.value && {
					tour_group_number: tourGroupNumber.value,
				}),
				...(tourName?.value && { tour_name: tourName.value }),
			};
			
			try {
				const { v1FormSubmitContactCreate } = authApi;
				const { status } = await v1FormSubmitContactCreate(
					formData as V1FormSubmitContactCreateRequestPayload,
				);

				if (status === 200) {
					progressStepChange('next');
				}
			} catch (e) {
				console.log(`error: ${(e as { error?: { message?: string } }).error?.message}`);
			}
		},
);

/**
 * 送出行程報名表單
**/
export const submitTourRegisterForm = createAction(
	'SUBMIT_TOUR_REGISTER_FORM',
	({
		progressStepChange,
		token
	} : {
		progressStepChange: (step: 'next' | 'back') => void;
		token: string;
	}) =>
		async (_: Dispatch, getState: GetState) => {
			const {
				form: { tourRegisterForm },
			} = getState();

			const {
				tourGroupNumber,
				tourName,
				dateStart,
				dateEnd,
				headcount,
				price,
				name,
				mobile,
				email,
				suggestion,
			} = tourRegisterForm;

			const headcountIncludeNumber = headcount.value.match(/\d+/);

			const formData = {
				tour_group_number: tourGroupNumber.value,
				tour_name: tourName.value,
				date_start: dateStart.value.split(" ")[0],
				date_end: dateEnd.value.split(" ")[0],
				headcount: headcountIncludeNumber ? parseInt(headcountIncludeNumber[0], 10): headcount.value,
				price: price.value,
				name: name.value,
				mobile: mobile.value,
				email: email.value,
				suggestion: suggestion.value ? suggestion.value : '',
			};

			const requestParams = {
				headers: {
					'X-VerifyCode-Token': token,
				}
			};

			try {
				const { v1FormSubmitRegisterCreate } = authApi;
				const { status } = await v1FormSubmitRegisterCreate(
					formData as V1FormSubmitRegisterCreateRequestPayload,
					requestParams
				);

				if (status === 200) {
					progressStepChange('next');
				}
			} catch (e) {
				console.log(`error: ${(e as { error?: { message?: string } }).error?.message}`);
			}
		},
);

/**
 * 送出活動報名表單
**/
export const submitEventRegisterForm = createAction(
	'SUBMIT_TOUR_REGISTER_FORM',
	({
		setStep,
		token
	} : {
		setStep: (step: string) => void;
		token: string;
	}) =>
		async (_: Dispatch, getState: GetState) => {
			const {
				form: { eventRegisterForm },
			} = getState();

			const {
				eventId,
				headcount,
				salesPerson,
				suggestion,
				name,
				mobile,
				email,
			} = eventRegisterForm;

			const headcountIncludeNumber = headcount.value.match(/\d+/);

			const formData = {
				event_id: parseInt(eventId.value, 10),
				headcount: headcountIncludeNumber ? parseInt(headcountIncludeNumber[0], 10): headcount.value,
				sales_person: salesPerson.value ? salesPerson.value : '',
				suggestion: suggestion.value ? suggestion.value : '',
				name: name.value,
				mobile: mobile.value,
				email: email.value,
			};

			const requestParams = {
				headers: {
					'X-VerifyCode-Token': token,
				}
			};

			try {
				const { v1FormSubmitEventCreate } = authApi;
				const { status } = await v1FormSubmitEventCreate(
					formData as V1FormSubmitEventCreateRequestPayload,
					requestParams
				);

				if (status === 200) {
					setStep('submit')
				}
			} catch (e) {
				console.log(`error: ${(e as { error?: { message?: string } }).error?.message}`);
			}
		},
);

export interface FormItem {
	value: HTMLInputElement['value'];
	error?: string;
}

export interface FormPayload {
	type?: string;
	key: string;
	value?: HTMLInputElement['value'];
	data: FormItem;
	error?: string;
}

export interface VerifyCodeFormItemPayload {
	value?: HTMLInputElement['value'];
	token?: string;
	error?: string;
}

export const defaultVerifyCode = {
	value: '',
	error: '',
};

export const defaultContactInquiryForm = {
	name: { value: '', error: '' },
	mobile: { value: '', error: '' },
	email: { value: '', error: '' },
	location: { value: '', error: '' },
	headcount: { value: '', error: '' },
	purpose: { value: '', error: '' },
	otherPurpose: { value: '', error: '' },
	suggestion: { value: '', error: '' },
	userId: { value: '', error: '' },
	tourGroupNumber: { value: '', error: '' },
	tourName: { value: '', error: '' },
};

export const defaultTourRegisterForm = {
	tourGroupNumber: { value: '', error: '' },
	tourName: { value: '', error: '' },
	dateStart: { value: '', error: '' },
	dateEnd: { value: '', error: '' },
	headcount: { value: '', error: '' },
	price: { value: '', error: '' },
	name: { value: '', error: '' },
	mobile: { value: '', error: '' },
	email: { value: '', error: '' },
	suggestion: { value: '', error: '' },
	userId: { value: '', error: '' },
};

export const defaultEventRegisterForm = {
	eventId: { value: '', error: '' },
	headcount: { value: '', error: '' },
	salesPerson: { value: '', error: '' },
	suggestion: { value: '', error: '' },
	name: { value: '', error: '' },
	mobile: { value: '', error: '' },
	email: { value: '', error: '' },
};

export interface FormItemProperty {
	value: HTMLInputElement['value'];
	error: string;
}

export interface contactInquiryFormProperty {
	name: FormItemProperty;
	mobile: FormItemProperty;
	email: FormItemProperty;
	location: FormItemProperty;
	headcount: FormItemProperty;
	purpose: FormItemProperty;
	otherPurpose: FormItemProperty;
	suggestion: FormItemProperty;
	[key: string]: FormItemProperty;
}

export interface tourRegisterFormProperty {
	tourGroupNumber: FormItemProperty;
	tourName: FormItemProperty;
	dateStart: FormItemProperty;
	dateEnd: FormItemProperty;
	headcount: FormItemProperty;
	price: FormItemProperty;
	name: FormItemProperty;
	mobile: FormItemProperty;
	email: FormItemProperty;
	suggestion: FormItemProperty;
	[key: string]: FormItemProperty;
}

export interface eventRegisterFormProperty {
	eventId: FormItemProperty;
	headcount: FormItemProperty;
	salesPerson: FormItemProperty;
	suggestion: FormItemProperty;
	name: FormItemProperty;
	mobile: FormItemProperty;
	email: FormItemProperty;
	[key: string]: FormItemProperty;
};

export interface State {
	loading: boolean;
	contactInquiryForm: contactInquiryFormProperty & {
		userId?: FormItemProperty;
		tourGroupNumber?: FormItemProperty;
		tourName?: FormItemProperty;
	};
	tourRegisterForm: tourRegisterFormProperty & {
		userId?: FormItemProperty;
		tourGroupNumber?: FormItemProperty;
		tourName?: FormItemProperty;
	};
	eventRegisterForm: eventRegisterFormProperty;
	verifyCode: FormItemProperty;
	[key: string]: any;
}

export const defaultState: State = {
	loading: false,
	contactInquiryForm: defaultContactInquiryForm,
	tourRegisterForm: defaultTourRegisterForm,
	eventRegisterForm: defaultEventRegisterForm,
	verifyCode: defaultVerifyCode,
	sentEmail: '',
};

export const reducer = {
	form: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			UPDATE_VERIFY_CODE_FORM: (state, action) => ({
				...state,
				verifyCode: {
					value: action.payload.value,
					error: action.payload.error,
				},
			}),
			
			UPDATE_FORM: (state, action) => ({
				...state,
				[(action.payload.type as string)]: {
					...state[action.payload.type as string],
					[action.payload.key]: {
						...state[action.payload.type as string][action.payload.key],
						...action.payload.data,
					},
				},
			}),

			UPDATE_CONTACT_INQUIRY_FORM: (state, action) => ({
				...state,
				error: '',
				contactInquiryForm: {
					...state.contactInquiryForm,
					[action.payload.key]: {
						...state.contactInquiryForm[action.payload.key],
						...action.payload.data,
						error: action.payload.error as string,
					},
				},
			}),

			UPDATE_SENT_EMAIL: (state, action) => ({
				...state,
				sentEmail: action.payload,
			}),
			
			CLEAR_FORM: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),
		},
		defaultState,
	),
};

const formActionsMap = {
	updateForm,
	updateVerifyCodeForm,
	updateContactInquiryForm,
	updateSentEmail,
	clearForm,
	sendVerifyCode,
	submitVerifyCode,
	submitContactInquiryForm,
	submitEventRegisterForm,
};

const mapHooksToState = (state: GlobalState) => ({
	contactInquiryForm: state.form.contactInquiryForm,
	tourRegisterForm: state.form.tourRegisterForm,
	eventRegisterForm: state.form.eventRegisterForm,
	verifyCode: state.form.verifyCode,
	sentEmail: state.form.sentEmail,
});

type FormSelector = ReturnType<typeof mapHooksToState>;
type FormActionsMap = typeof formActionsMap;

export const useForm = () =>
	useRedux<FormSelector, FormActionsMap>(
		mapHooksToState,
		formActionsMap,
	);
