import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import {
	getTourDeparturesList,
	clearTourFilter,
	setTourFilter,
	TourFilterField,
} from 'models/tour';
import { isExist } from 'util/helper';

const routes: CustomRoute = {
	path: '/tour-list',
	components: () => [import('./component')],
	render: ([TourList]) => <TourList />,
	onEnter: async ({ next, store, query }) => {
		const { countries, tags, dateStart, dateEnd } = query;
		store.dispatch(clearTourFilter());

		const filters = [
			{ field: 'countries', value: countries },
			{ field: 'tags', value: tags },
			{ field: 'dateStart', value: dateStart },
			{ field: 'dateEnd', value: dateEnd },
		];

		filters.forEach(({ field, value }) => {
			if (isExist(value)) {
				store.dispatch(setTourFilter({ field: field as TourFilterField, value }));
			}
		});

		await store.dispatch(getTourDeparturesList(1));

		const children = await next();
		return children;
	},
};

export default routes;
