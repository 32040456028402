import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { api } from 'util/api';

import { CountryIndexResource } from 'util/api/swaggerApi/data-contracts';
import { State as GlobalState } from './reducers';

export const getAllCountries = createAction<Promise<CountryIndexResource[]>>('GET_COUNTRIES', async () => {
	const { v1CountryList } = api;
	const { data, status } = await v1CountryList();

	if (status !== 200 && status !== 201) {
		return [];
	}

	return data?.data as CountryIndexResource[];
});

export interface State {
	loading: boolean;
	countries: CountryIndexResource[];
}

export const defaultState: State = {
	loading: false,
	countries: [],
};

export const reducer = {
	countries: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_COUNTRIES_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_COUNTRIES_FULFILLED: (state, action: Action<CountryIndexResource[]>) => ({
				...state,
				countries: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const exploreTourActionsMap = {
	getAllCountries,
};

const mapHooksToState = (state: GlobalState) => ({
	countries: state.countries.countries,
});

type ExploreTourSelector = ReturnType<typeof mapHooksToState>;
type ExploreTourActionsMap = typeof exploreTourActionsMap;

export const useExploreTour = () =>
	useRedux<ExploreTourSelector, ExploreTourActionsMap>(mapHooksToState, exploreTourActionsMap);