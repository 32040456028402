import { createAction, handleActions, Action } from 'redux-actions';
import { createSelector } from 'reselect';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';
import { api, authApi } from 'util/api';
import {
	V1AuthUserCreateRequestPayload,
	V1AuthLoginCreateRequestPayload,
	V1AuthUserResetPasswordCreateRequestPayload,
} from 'util/api/swaggerApi/data-contracts';
import storage from 'util/storage';
import { isExist } from 'util/helper';

import { sendVerifyCode } from 'models/form';
import { openModal, TOAST_TYPE, MODAL_CATEGORY } from 'models/modal';
import { getUser, updateUserForm } from 'models/user';

import { GetState, State as GlobalState } from './reducers';

export const updateAuthForm = createAction(
	'UPDATE_AUTH_FORM',
	({ type, key, data, error }: FormPayload) => ({
		type,
		key,
		data,
		error,
	}),
);

export const clearForm = createAction('CLEAR_FORM', (type: string) => {
	let clearData;

	switch (type) {
		case 'loginForm':
			if (isExist(getEmailFromStorage())) {
	      clearData = {
	        email: {
	          value: getEmailFromStorage(),
	          error: '',
	        },
	        password: {
	          value: '',
	          error: '',
	        },
	        remember: {
	          value: true,
	          error: '',
	        },
	      };
			} else {
				clearData = defaultLoginForm;
			}
			break;
		case 'signUpForm':
			clearData = defaultSignUpForm;
			break;
		case 'forgotPasswordForm':
			clearData = defaultForgotPasswordForm;
			break;
		case 'resetPasswordForm':
			clearData = defaultResetPasswordForm;
			break;
		default:
			break;
	};
		
	  return {
	    type,
	    data: clearData,
	  };
});

export const updateAccessToken = createAction('UPDATE_ACCESS_TOKEN', (newToken: string) => {
	storage.setToken(newToken);
	return newToken;
});

export const loadTokenFromLocalStorage = createAction('LOAD_TOKEN_FROM_LOCAL_STORAGE', () => {
	const token = storage.getToken();
	return token;
});

export const updateAuthToken = createAction('UPDATE_AUTH_TOKEN',
	({ type, token }: { type: string, token: string }) => ({ type, token }));

export const setEmailToStorage = (email: string) => storage.setItem('email', email);
export const getEmailFromStorage = () => storage.getItem('email');

export const logout = createAction('LOGOUT', () => async (dispatch: Dispatch) => {
	const { v1AuthLogoutCreate } = authApi;
	const { status } = await v1AuthLogoutCreate();
	
	if (status === 200) {	
		storage.removeToken();
		dispatch(updateAccessToken(''));
		dispatch(
			openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '登出成功' }),
		);
		window.location.reload();
	}
	return null;
});

export const login = createAction(
	'LOGIN',
	({
		closeModal: closeLoginModal,
		setStep,
		isLoginAfterSignUp = false,
	} : {
		closeModal?: () => void;
		setStep: (step: string) => void;
		isLoginAfterSignUp?: boolean
	}) =>
		async (dispatch: Dispatch, getState: GetState): Promise<void> => {
			const {
				auth: { loginForm, signUpForm },
			} = getState();

			const getFormData = () => {
				const form = isLoginAfterSignUp ? signUpForm : loginForm;
				return {
					email: form.email.value,
					password: form.password.value,
				};
			};
		
			const formData = getFormData();

			try {
				const { v1AuthLoginCreate } = api;
				const { status, data } = await v1AuthLoginCreate(formData as V1AuthLoginCreateRequestPayload);

				if (status === 200) {
					if (isLoginAfterSignUp) {
						setStep('signUpResultSuccess');
					} else {
						dispatch(
							openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '登入成功' }),
						);
						if (closeLoginModal) {
							closeLoginModal();
						}
					}

					setTimeout(() => {
						dispatch(getUser());
					}, 300);
				}

				if (loginForm.remember.value) {
					setEmailToStorage(loginForm.email.value);
				} else {
					setEmailToStorage('');
				}

				dispatch(updateAccessToken(data.data.token));
			} catch (e) {
				const errorCode = (e as { error: { errorCode: string } }).error?.errorCode;

				switch (errorCode) {
					case 'login_failed':
						dispatch(updateAuthForm({
							type: 'loginForm',
							key: 'password',
							data: { value: loginForm.password.value, error: '請確認帳號密碼並再試一次' },
						}));
						break;
					case 'account_disable':
						dispatch(updateAuthForm({
							type: 'loginForm',
							key: 'email',
							data: { value: loginForm.email.value, error: '此 Email 帳號已被刪除，請更換其他 Email 再試一次或聯繫客服協助' },
						}));
						break;
					default:
						dispatch(
							openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: '登入失敗，請再試一次' }),
						);
						break;
				}
			}
		},
);

export const signUp = createAction(
	'SIGN_UP',
	({ setStep, verifyCodeToken } : { setStep: (step: string) => void; verifyCodeToken: string; }) =>
		async (dispatch: Dispatch, getState: GetState): Promise<void> => {
			const {
				auth: { signUpForm, authToken },
			} = getState();

			const {
				email,
				password,
				name,
				mobile,
				isParticipated,
			} = signUpForm;
			
			const formData = {
				email: email.value,
				password: password.value,
				name: name.value,
				mobile: mobile.value,
				is_participated: isParticipated.value ? '1' : '0',
			};
			
			const requestParams = {
				headers: {
					'X-User-Mail-Check-Token': authToken.checkEmail,
					'X-VerifyCode-Token': verifyCodeToken,
				}
			};

			try {
				const { v1AuthUserCreate } = api;
				const { status } = await v1AuthUserCreate(
					formData as V1AuthUserCreateRequestPayload,
					requestParams
				);

				if (status === 200) {
					dispatch(login({ setStep, isLoginAfterSignUp: true }));
					dispatch(updateAuthToken({ type: 'verifyCode', token: '' })); // 註冊成功後，清除 verifyCode token
					dispatch(updateAuthToken({ type: 'checkEmail', token: '' })); // 註冊成功後，清除 checkEmail token
				}
			} catch (e) {
				setStep('signUpResultFail');
			}
		},
);

export const resetPassword = createAction(
	'RESET_PASSWORD',
	({ setStep }: { setStep: (step: string) => void; }) =>
		async (dispatch: Dispatch, getState: GetState): Promise<void> => {
			const {
				auth: { forgotPasswordForm, resetPasswordForm, authToken },
			} = getState();

			const formData = {
				email: forgotPasswordForm.email.value,
				password: resetPasswordForm.password.value,
			};
			
			const requestParams = {
				headers: {
					'X-User-Mail-Check-Token': authToken.checkEmail,
					'X-VerifyCode-Token': authToken.verifyCode,
				}
			};

			try {
				const { v1AuthUserResetPasswordCreate } = authApi;
				const { status } = await v1AuthUserResetPasswordCreate(
					formData as V1AuthUserResetPasswordCreateRequestPayload,
					requestParams
				);
				
				if (status === 200) {
					setStep('login');
					dispatch(
						openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.SUCCESS, data: '密碼已重設成功，請使用新密碼登入' }),
					);
					dispatch(updateAuthToken({ type: 'verifyCode', token: '' })); // 密碼設定成功後，清除 verifyCode token
					dispatch(updateAuthToken({ type: 'checkEmail', token: '' })); // 密碼設定成功後，清除 checkEmail token
				}
			} catch (e) {
				dispatch(
					openModal({ category: MODAL_CATEGORY.TOAST, type: TOAST_TYPE.WARNING, data: '重設密碼失敗，請再試一次' }),
				);
			}
		},
);

export const checkUserEmail = createAction(
	'CHECK_EMAIL',
	({ checkType, setPreviousStep, setStep }
		:
	{
		checkType: 'register' | 'reset-password' | 'update-email';
		setPreviousStep?: (step: string) => void;
		setStep: (step: string) => void;
	}) =>
		async (dispatch: Dispatch, getState: GetState): Promise<void> => {
			const {
				auth: { signUpForm, forgotPasswordForm },
      	user: { userForm },
			} = getState();
			let email;
			
			switch (checkType) {
				case 'register':
					email = signUpForm.email.value;
					break;
				case 'reset-password':
					email = forgotPasswordForm.email.value;
					break;
				case 'update-email':
					email = userForm.email.value;
					break;
			
				default:
					email = '';
					break;
			}
			
			try {
				const { v1AuthUserEmailCheckCreate } = api;
				const { status, data } = await v1AuthUserEmailCheckCreate({
					email,
					check_type: checkType
				});

				if (status === 200) {
					setStep('verifyCode'); // 換頁到 VerifyCode 頁面

					if (setPreviousStep) {
						setPreviousStep(checkType === 'register' ? 'signUp' : 'forgotPassword'); // 用來判斷從哪個頁面進入 VerifyCode 頁面
					};

					dispatch(updateAuthToken({ type: 'checkEmail', token: data?.data }));

					switch (checkType) {
						case 'register':
							dispatch(sendVerifyCode({
								email: signUpForm.email.value,
								verifyType: 'member-register',
							}));
							break;
						case 'reset-password':
							dispatch(sendVerifyCode({
								email: forgotPasswordForm.email.value,
								verifyType: 'member-resetPassword',
							}));
							break;
						case 'update-email':
							dispatch(sendVerifyCode({
								email: userForm.email.value,
								verifyType: 'member-updateUserEmail',
							}));
							break;
					
						default:
							break;
					}
				}
			} catch (e) {
				const errorCode = (e as { error: { errorCode: string } }).error?.errorCode;
				const formType = checkType === 'register' ? 'signUpForm' : 'forgotPasswordForm';
				const emailValue = formType === 'signUpForm' ? signUpForm.email.value : forgotPasswordForm.email.value;
				const errorMessages = {
					account_disable: '此 Email 帳號已被刪除，請更換其他 Email 再試一次或聯繫客服協助',
					user_not_found: '您尚未註冊，請先進行註冊',
					has_registered: '此 Email 已被註冊，請重新確認',
				};
				
				const updateAuthFormError = ({ key, value, error } : { key: string; value: string; error: string; }) => {
					dispatch(updateAuthForm({
						type: formType,
						key,
						data: { value, error },
					}));
				};
				switch (errorCode) {
					case 'account_disable':
						if (checkType === 'register' || checkType === 'reset-password') {
							updateAuthFormError({ key: 'email', value: emailValue, error: errorMessages[errorCode] })
						} else {
							dispatch(updateUserForm({
								key: 'email',
								value: userForm.email.value,
								error: errorMessages[errorCode]
							}));
						};
						break;
					case 'user_not_found':
						dispatch(updateAuthForm({
							type: 'forgotPasswordForm',
							key: 'email',
							data: { value: forgotPasswordForm.email.value, error: errorMessages[errorCode] },
						}));
						break;
					case 'has_registered':
						if (checkType === 'register' || checkType === 'reset-password') {
							dispatch(updateAuthForm({
								type: 'signUpForm',
								key: 'email',
								data: { value: signUpForm.email.value, error: errorMessages[errorCode] },
							}));
						} else {
							dispatch(updateUserForm({
								key: 'email',
								value: userForm.email.value,
								error: errorMessages[errorCode]
							}));
						};
						break;
					default:
						break;
				}
			}
		},
);

export interface FormItem {
	value: HTMLInputElement['value'] | boolean;
	error?: string;
}

export interface FormPayload {
	type?: string;
	key: string;
	value?: HTMLInputElement['value'];
	data: FormItem;
	error?: string;
}

export interface FormItemProperty {
	value: HTMLInputElement['value'];
	error: string;
}

export interface FormBooleanItem {
	value: boolean;
	error: string;
}

export interface LoginFormProperty {
	email: FormItemProperty;
	password: FormItemProperty;
	remember: FormBooleanItem;
	[key: string]: FormItemProperty | FormBooleanItem;
}

export interface AuthTokenProperty {
	checkEmail: string;
	verifyCode: string;
	[key: string]: string;
}

export interface SignUpFormProperty {
	email: FormItemProperty;
	password: FormItemProperty;
	name: FormItemProperty;
	mobile: FormItemProperty;
	isParticipated: FormBooleanItem;
	[key: string]: FormItemProperty | FormBooleanItem;
}

export interface ForgotPasswordFormProperty {
	email: FormItemProperty;
	[key: string]: FormItemProperty;
}

export interface ResetPasswordFormProperty {
	password: FormItemProperty;
	confirmPassword: FormItemProperty;
	[key: string]: FormItemProperty | string;
}

export const defaultLoginForm = {
	email: { value: '', error: '' },
	password: { value: '', error: '' },
	remember: { value: false, error: '' },
};

export const defaultSignUpForm = {
	email: { value: '', error: '' },
	password: { value: '', error: '' },
	name: { value: '', error: '' },
	mobile: { value: '', error: '' },
	isParticipated: { value: true, error: '' },
};

export const defaultForgotPasswordForm = {
	email: { value: '', error: '' },
};

export const defaultResetPasswordForm = {
	password: { value: '', error: '' },
	confirmPassword: { value: '', error: '' },
};

export const defaultAuthToken = {
	checkEmail: '',
	verifyCode: '',
};

export interface State {
  loading: boolean;
	token: string | null;
	authToken: AuthTokenProperty;
	loginForm: LoginFormProperty;
	signUpForm: SignUpFormProperty;
	forgotPasswordForm: ForgotPasswordFormProperty;
	resetPasswordForm: ResetPasswordFormProperty;
	[key: string]: any;
}

export const defaultState: State = {
	loading: false,
	token: null,
	authToken: defaultAuthToken,
	loginForm: defaultLoginForm,
	signUpForm: defaultSignUpForm,
	forgotPasswordForm: defaultForgotPasswordForm,
	resetPasswordForm: defaultResetPasswordForm,
};

export const reducer = {
	auth: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			UPDATE_AUTH_FORM: (state, action) => ({
				...state,
				[(action.payload.type as string)]: {
					...state[action.payload.type as string],
					[action.payload.key]: {
						...state[action.payload.type as string][action.payload.key],
						...action.payload.data,
					},
				},
			}),
			
			CLEAR_FORM: (state, action) => ({
				...state,

				[action.payload.type]: action.payload.data,
			}),

			UPDATE_ACCESS_TOKEN: (state, action: Action<string>) => ({
				...state,
				token: action.payload,
			}),

			LOGIN_PENDING: state => ({
				...state,
				loading: true,
			}),

			LOGIN_FULFILLED: state => ({
				...state,
				loading: false,
			}),

			LOAD_TOKEN_FROM_LOCAL_STORAGE: (state, action: Action<string>) => ({
				...state,
				token: action.payload,
			}),

			UPDATE_AUTH_TOKEN: (state, action) => ({
				...state,
				authToken: {
					...state.authToken,
					[action.payload.type]: action.payload.token,
				},
			}),
		},
		defaultState,
	),
};

const selectAuth = createSelector(
	(state: GlobalState) => state.auth.token,
	(state: GlobalState) => state.auth.authToken,
	(state: GlobalState) => state.auth.loginForm,
	(state: GlobalState) => state.auth.signUpForm,
	(state: GlobalState) => state.auth.forgotPasswordForm,
	(state: GlobalState) => state.auth.resetPasswordForm,
	(token, authToken, loginForm, signUpForm, forgotPasswordForm, resetPasswordForm) => ({
		token,
		isLogin: isExist(token),
		authToken,
		loginForm,
		signUpForm,
		forgotPasswordForm,
		resetPasswordForm,
	}),
);

const authActionsMap = {
	login,
	signUp,
	logout,
	resetPassword,
	updateAuthForm,
	clearForm,
	checkUserEmail,
};
export const useAuth = () => useRedux(selectAuth, authActionsMap);
