import React from 'react';

import { lazyExecute, isExist } from 'util/helper';
import { CustomRoute } from 'util/hook/useRouter';
import storage from 'util/storage';

import { getHomePageList } from 'models/home';
import { getBanners } from 'models/banner';
import { getEmailFromStorage, updateAuthForm } from 'models/auth';
import { getUser } from 'models/user';
import { getEvents } from 'models/events';
import { getTravelBlog } from 'models/travelBlog';
import { clearTourFilter } from 'models/tour';

import TourRoute from './Tours';
import TourListRoute from './TourList';
import MemberRoute from './Member';
import EventsRoute from './Events';
import TravelBlogRoute from './TravelBlog';
import SearchRoute from './Search';
import TripHandbookRoute from './TripHandbook';
import PromotionalOffersRoute from './PromotionalOffers';

const routes: CustomRoute = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ store, next }) => {
		console.log('on Enter Root');

		const token = storage.getToken();
		if (isExist(token)) {
			await store.dispatch(getUser());
		}

		const storageEmail = getEmailFromStorage();

		if (isExist(storageEmail)) {
			store.dispatch(
				updateAuthForm({
					type: 'loginForm',
					key: 'email',
					data: { value: storageEmail as string },
				}),
			);
			store.dispatch(
				updateAuthForm({
					type: 'loginForm',
					key: 'remember',
					data: { value: true },
				}),
			);
		}

		const children = await next();
		lazyExecute(() => {
			window.scrollTo({
				top: 0,
				left: 0,
			});
		});
		console.log('on Enter Root / end');

		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'home' */ './Home')],
			render: ([Home]) => <Home />,
			onEnter: async ({ next, store }) => {
				await store.dispatch(getHomePageList());
				await store.dispatch(getEvents({ type: 'lecture' })); // 免費旅遊講座
				await store.dispatch(getEvents({ type: '' })); // 華友活動
				store.dispatch(clearTourFilter());
				store.dispatch(getBanners());
				store.dispatch(getTravelBlog({}));			

				const children = await next();
				return children;
			},
		},
		TourRoute,
		TourListRoute,
		MemberRoute,
		EventsRoute,
		TravelBlogRoute,
		SearchRoute,
		TripHandbookRoute,
		PromotionalOffersRoute,
	],
};

export default routes;
