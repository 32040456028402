import React from 'react';
import classnames from 'classnames';

import { SelectValue } from 'types/SelectValue';
import Radio from 'components/atoms/Radio';

import styles from './index.module.css';

interface RadioGroupProperty {
	className?: string;
	radioItemClassName?: string;
	label: string;
	required?: boolean;
	data: SelectValue[];
	value?: string | number | boolean;
	disabled?: boolean;
	onChange?: (v: SelectValue[]) => void;
}

const RadioGroup: React.FC<RadioGroupProperty> = ({
	className,
	radioItemClassName,
	label = '',
	required = false,
	data = [],
	value = '',
	disabled = false,
	onChange = () => {},
}) => (
	<div
		className={classnames(styles.radioGroup, className)}
	>
		<div className={styles.radioGroupLabel}>
			{label}
			{required && <sup className={styles.requireHint}>*</sup>}
		</div>
		{data.map(d => (
			<Radio
				key={d.value}
				className={classnames(radioItemClassName, styles.radioItem, {
					[styles.disabled]: disabled,
				})}
				value={d.value}
				label={d.label}
				isSelected={value === d.value}
				onChange={() => {
					if (!disabled) {
						onChange([d]);
					}
				}}
			/>
		))}
	</div>
);

export default RadioGroup;
