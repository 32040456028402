import React, { useState, useRef } from 'react';
import classnames from 'classnames';

import { useBoolean } from 'util/hook';
import { isExist } from 'util/helper';

import { useHistory } from 'models/routing';
import { useGlobal } from 'models/global';

import Icon from 'components/atoms/Icon';
import CircleButton from 'components/atoms/CircleButton';

import SearchIcon from 'images/icon/search-dark.inline.svg';
import IconClose from 'images/icon/close.inline.svg';
import styles from './index.module.css';

const Search: React.FC = () => {
	const history = useHistory();
	const searchBoxRef = useRef<HTMLDivElement>(null);
	const searchInputRef = useRef<HTMLDivElement>(null);
	const [value, setValue] = useState('');
	const [{ isNavMenuOpen, isMemberMenuOpen }, { setNavClose, setMemberMenuClose }] = useGlobal();
	const [isSearchFieldOpen, { setFalse: closeSearchField, toggle: toggleSearchField }] = useBoolean(
		{
			defaultBoolean: false,
		},
	);

	const handleClickSearchWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
		// 如果 e.target 的 parentElement 是 searchBoxRef.current 就不要關閉 search field
		if (searchBoxRef.current && searchBoxRef.current.contains(e.target as HTMLElement)) {
			return;
		}

		if ((e.target as HTMLElement).tagName !== 'svg' && e.target !== searchBoxRef.current && e.target !== searchInputRef.current) {
			closeSearchField();
		}
	};

	const handleClickSearchButton = () => {
		if (isNavMenuOpen) {
			setNavClose();
		}

		if (isMemberMenuOpen) {
			setMemberMenuClose();
		}
		
		toggleSearchField();
	};

	const onSearch: () => void = () => {
		if (isExist(value)) {
			history.push(`/search?q=${value}`);
			history.go(0);
		}
	};

	return (
		<div className={styles.search} role="presentation" onClick={handleClickSearchWrapper}>
			<Icon size="small" src={SearchIcon} onClick={handleClickSearchButton} />
			<div
				className={classnames(styles.searchBoxWrapper, {
					[styles.open]: isSearchFieldOpen,
					[styles.close]: !isSearchFieldOpen,
				})}
			>
				<div className={styles.searchInput} ref={searchBoxRef}>
					<p className={styles.title}>SEARCH</p>
					<Icon className={styles.close} src={IconClose} onClick={closeSearchField} />
					<div className={styles.inputWrapper} ref={searchInputRef}>
						<input
							type="text"
							value={value}
							placeholder="請輸入要搜尋的內容"
							onChange={e => setValue(e.target.value)}
						/>
						<CircleButton
							className={styles.submitButton}
							searchIconOnly
							hasBackground
							onClick={onSearch}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Search;
