import { createAction, handleActions } from 'redux-actions';
import { createContext, useContext } from 'react';
import { createSelector } from 'reselect';
import { Location, History } from 'history';
import { useRedux } from 'util/hook/redux';

import history from 'store/history';

export const routeChange = createAction<Location, Location>(
	'ROUTE_LOCATION_CHANGE',
	(location: Location) => location,
);

export type State = Location;

export const defaultState: State = { ...history.location };

export const reducer = {
	routing: handleActions<Location, Location>(
		{
			ROUTE_LOCATION_CHANGE: (state, action) => ({
				...state,
				...action.payload,
			}),
		},
		defaultState,
	),
};

const selectRouting = createSelector(
	state => state.routing.pathname,
	pathname => ({
		pathname,
	}),
);

export const useRouting = () => useRedux(selectRouting, {});

export const HistoryContext = createContext<History>(history);

export const useHistory = (): History => useContext(HistoryContext);
