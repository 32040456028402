import React, { useState, useRef, useEffect, createRef } from 'react';
import classnames from 'classnames';

import { isExist } from 'util/helper';

import styles from './index.module.css';

interface VerificationCodeInputProperty {
	className?: string;
	size?: 'normal' | 'small';
	initValue?: string;
	inputLength?: number;
	error?: boolean;
	onChange: (s: string) => void;
}

const VerificationCodeInput: React.FC<VerificationCodeInputProperty> = ({
	className,
	size = 'normal',
	initValue = '',
	inputLength = 6,
	onChange = () => {},
	error = false,
}) => {
	const [codes, setCodes] = useState(() => {
		const array = Array(inputLength).fill('');
		if (isExist(initValue)) {
			return array.map((_, index) => initValue[index] || '');
		}
		return array;
	});
	const inputRefs = useRef(codes.map(() => createRef()));

	const handleInputChange = (index: number, value: string) => {
		if (value.length > 1) return;

		const newCodes = [...codes];
		newCodes[index] = value;
		setCodes(newCodes);

		if (value !== '' && index < codes.length - 1) {
			(inputRefs.current[index + 1].current as HTMLInputElement).focus();
		}
	};

	const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Backspace' && codes[index] === '' && index > 0) {
			(inputRefs.current[index - 1].current as HTMLInputElement).focus();
		}
	};

	useEffect(() => {
		const firstInputRef = inputRefs.current[0].current as HTMLInputElement;
		if (firstInputRef) {
			firstInputRef.focus();
		}
	}, []);

	useEffect(() => {
		if (typeof onChange === 'function') {
			onChange(codes.join(''));
		}
	}, [codes]);

	return (
		<div className={classnames(styles.verificationCodeInputWrapper, className)}>
			{codes.map((code, index) => (
				<input
					// eslint-disable-next-line react/no-array-index-key
					key={index}
					ref={(inputRefs.current[index] as React.LegacyRef<HTMLInputElement>)}
					className={classnames(styles.codeInput, {
						[styles.small]: size === 'small',
						[styles.error]: error,
					})}
					type="text"
					maxLength={1}
					value={code}
					onChange={e => handleInputChange(index, e.target.value)}
					onKeyDown={e => handleKeyDown(index, e)}
				/>
			))}
		</div>
	);
};

export default VerificationCodeInput;