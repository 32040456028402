import React from 'react';
import classnames from 'classnames';
import Lottie from 'react-lottie';

import { useMedia } from 'util/hook/useMedia';

import loadingData from './loading.json';

import styles from './index.module.css';

const Loading: React.FC = () => {
	const media = useMedia();

	const style = {
		width: media === 'desktop' ? 285 : 230,
		height: media === 'desktop' ? 60 : 50,
	};

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loadingData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	return (
		<div className={classnames(styles.loading)}>
			<Lottie options={defaultOptions} style={style} />
		</div>
	);
};

export default Loading;
