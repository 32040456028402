import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export interface PortalProperty {
	children: React.ReactNode;
}

const Portal: React.FC<PortalProperty> = ({ children }) => {
	const eleRef = useRef(document.createElement('div'));

	useEffect(() => {
		document.body.appendChild(eleRef.current);

		return () => {
			document.body.removeChild(eleRef.current);
		};
	}, []);

	return ReactDOM.createPortal(children, eleRef.current);
};

export default Portal;
