import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	EventIndexResource,
	GetListDataPaginator,
	V1EventsListRequestParams,
	EventShowResource
} from 'util/api/swaggerApi/data-contracts';

import { State as GlobalState } from './reducers';

/**
 * 取得活動列表
 * @param type 活動類型
 * @param tags 活動標籤
 * @param isFeatured 是否取得精選活動
 * @param page 頁碼
 */
export const getEvents = createAction(
	'GET_EVENTS',
	(params: V1EventsListRequestParams) =>
		async (dispatch: Dispatch) => {
			const { type, tags, is_featured: isFeatured, page } = params;
			const { v1EventsList } = api;
			const { data, message } = await v1EventsList({
				type,
				tags,
				is_featured: isFeatured,
				page,
			});

			if (message !== 'success') {
				return [];
			}

			dispatch(setPaginationMetaData((data as { meta: GetListDataPaginator })?.meta));

			if (type === 'lecture') {
				dispatch(setLectureEvents(data?.data || [] as EventIndexResource[]));
				return null;
			}

			if (isFeatured) {
				dispatch(setFeaturedEvents(data?.data || [] as EventIndexResource[]));
			}

			return data?.data || [] as EventIndexResource[];
		}
);

/**
 * 取得精選活動
 */
export const setFeaturedEvents = createAction(
	'SET_FEATURED_EVENTS', 
	(featuredEvents: EventIndexResource[]) => featuredEvents
);


/**
 * 取得講座活動
 */
export const setLectureEvents = createAction(
	'SET_LECTURE_EVENTS', 
	(lectureEvents: EventIndexResource[]) => lectureEvents
);

/**
 * 取得活動內頁資料
 */
export const getEventDataById = createAction<Promise<EventShowResource>, number>(
	'GET_EVENT_DATA_BY_ID',
	async id => {
		const { v1EventDetail } = api;
		const { data, message } = await v1EventDetail(id);

		if (message !== 'success') {
			return {};
		}

		return data?.data as EventShowResource;
	},
);

export const setPaginationMetaData = createAction<GetListDataPaginator, GetListDataPaginator>(
	'SET_PAGINATION_META_DATA',
	paginationMetaData => paginationMetaData,
);

export interface State {
  loading: boolean;
  eventsList: EventIndexResource[];
	lectureEventsList: EventIndexResource[];
  featuredEventsList: EventIndexResource[];
	eventData: EventShowResource,
	paginationMetaData?: GetListDataPaginator;
}

export const defaultState: State = {
	loading: false,
	eventsList: [],
	lectureEventsList: [],
	featuredEventsList: [],
	eventData: {},
	paginationMetaData: {},
};

export const reducer = {
	events: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_EVENTS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_EVENTS_FULFILLED: (state: State, action: Action<EventIndexResource[]>): State => ({
				...state,
				loading: false,
				eventsList: action.payload,
			}),

			SET_PAGINATION_META_DATA: (state: State, action: Action<GetListDataPaginator>): State => ({
				...state,
				paginationMetaData: action.payload,
			}),

			SET_FEATURED_EVENTS: (state: State, action: Action<EventIndexResource[]>): State => ({
				...state,
				featuredEventsList: action.payload,
			}),

			SET_LECTURE_EVENTS: (state: State, action: Action<EventIndexResource[]>): State => ({
				...state,
				lectureEventsList: action.payload,
			}),
			
			GET_EVENT_DATA_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_EVENT_DATA_BY_ID_FULFILLED: (state, action: Action<EventShowResource>) => ({
				...state,
				loading: false,
				eventData: action.payload,
			}),
		},
		defaultState,
	),
};

const selectEvents = (state: GlobalState) => ({
	eventsList: state.events.eventsList,
	lectureEventsList: state.events.lectureEventsList,
	featuredEventsList: state.events.featuredEventsList,
	eventData: state.events.eventData,
	paginationMetaData: state.events.paginationMetaData,
});

export const useEvents = () => useRedux(selectEvents, {
	getEvents,
	getEventDataById,
});